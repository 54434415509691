import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { Ability, AbilityBuilder } from '@casl/ability';
import { Role } from '@actimi/core-migration';

const ability = new Ability();
const abilityCtx = createContext(ability);
const Can = createContextualCan(abilityCtx.Consumer);

export const createRulesWithRoleAbility = (role: Role) => {
  const roleContextAbility = role?.context?.ability;
  if (typeof roleContextAbility !== 'object')
    throw new Error('User has no role context ability');

  const { can, rules } = new AbilityBuilder(Ability);

  Object.keys(roleContextAbility).forEach((resourceType) => {
    can(roleContextAbility[resourceType], resourceType);
  });

  return rules;
};

export const AbilityContext = {
  abilityCtx,
  Can,
  ability
};
