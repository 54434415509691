import App from './App';
import './index.css';
import ReactDOM from 'react-dom';
import 'src/utils/chart';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import store from 'src/store';
import { SidebarProvider } from './contexts/SidebarContext';
import { AuthProvider } from './contexts/JWTAuthContext';
import ConfirmProvider from './components/Confirm';
import { ChatConnStatusProvider } from 'src/contexts/ChatConnStatus';

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <SidebarProvider>
        <BrowserRouter>
          <AuthProvider>
            <ConfirmProvider>
              <ChatConnStatusProvider>
                <App />
              </ChatConnStatusProvider>
            </ConfirmProvider>
          </AuthProvider>
        </BrowserRouter>
      </SidebarProvider>
    </Provider>
    {/* <script src="https://meet.jit.si/external_api.js"></script> */}
  </HelmetProvider>,
  document.getElementById('root')
);

// serviceWorker.register();
