import { ButtonProps } from '@mui/material';
import React, { useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from './ConfirmationDialog';

interface IOptions {
  title: string;
  description: string;
  confirmationText: string;
  cancellationText: string;
  hideCancel?: boolean;
  allowClose?: boolean;
  confirmationButtonProps?: ButtonProps;
}

const ConfirmContext =
  React.createContext<(options?: Partial<IOptions>) => Promise<unknown>>(null);

const ConfirmProvider: React.FC<{ defaultOptions?: Partial<IOptions> }> = ({
  children,
  defaultOptions = {}
}) => {
  const { t } = useTranslation();

  const DEFAULT_OPTIONS: IOptions = {
    title: t('Are you sure ?'),
    description: '',
    confirmationText: t('Ok'),
    cancellationText: t('Cancel'),
    hideCancel: false,
    allowClose: true,
    confirmationButtonProps: {}
  };

  const buildOptions = (
    defaultOptions: Partial<IOptions>,
    options: Partial<IOptions>
  ) => {
    return {
      ...DEFAULT_OPTIONS,
      ...defaultOptions,
      ...options
    };
  };

  const [options, setOptions] = useState({
    ...DEFAULT_OPTIONS,
    ...defaultOptions
  });
  const [resolveReject, setResolveReject] = useState([]);
  const [resolve, reject] = resolveReject;

  const confirm = useCallback((options: Partial<IOptions> = {}) => {
    return new Promise((resolve, reject) => {
      setOptions(buildOptions(defaultOptions, options));
      setResolveReject([resolve, reject]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  const handleCancel = useCallback(() => {
    if (reject) {
      reject();
      handleClose();
    }
  }, [reject, handleClose]);

  const handleConfirm = useCallback(() => {
    if (resolve) {
      resolve();
      handleClose();
    }
  }, [resolve, handleClose]);

  return (
    <>
      <ConfirmContext.Provider value={confirm}>
        {children}
      </ConfirmContext.Provider>
      <ConfirmationDialog
        open={resolveReject.length === 2}
        options={options}
        onClose={handleClose}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export const useConfirm = () => {
  const confirm = useContext(ConfirmContext);
  return confirm;
};

export default ConfirmProvider;
