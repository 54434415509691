import { ILimitValues } from '@actimi/core-migration';

export const DEFAULT_LIMIT_VALUES: ILimitValues = {
  id: '',
  patient: {
    resourceType: 'Patient',
    id: ''
  },
  organization: {
    id: '',
    resourceType: 'Organization'
  },
  bloodPressure: {
    low: { max: 140, min: 100, riskScore: 2 },
    medium: { max: 160, min: 90, riskScore: 3 },
    high: { max: 180, min: 80, riskScore: 4 }
  },
  bloodPressureDiastolic: {
    low: { max: 90, min: 60, riskScore: 2 },
    medium: { max: 99, min: 50, riskScore: 3 },
    high: { max: 110, min: 40, riskScore: 4 }
  },
  bodyWeight: [
    {
      timespan: 1,
      limit: {
        low: { max: 1.4, min: 0.9, riskScore: 2 },
        medium: { max: 0, min: 0, riskScore: 3 },
        high: { max: 0, min: 0, riskScore: 4 }
      }
    },
    {
      timespan: 3,
      limit: {
        low: { max: 2.5, min: 1, riskScore: 2 },
        medium: { max: 0, min: 0, riskScore: 3 },
        high: { max: 0, min: 0, riskScore: 4 }
      }
    },
    {
      timespan: 7,
      limit: {
        low: { max: 1.8, min: 1.4, riskScore: 2 },
        medium: { max: 0, min: 0, riskScore: 3 },
        high: { max: 0, min: 0, riskScore: 4 }
      }
    }
  ],
  heartRate: {
    low: { max: 100, min: 60, riskScore: 2 },
    medium: { max: 120, min: 50, riskScore: 3 },
    high: { max: 140, min: 40, riskScore: 4 }
  },
  symptoms: {
    low: { max: 2, min: 0, riskScore: 2 },
    medium: { max: 3, min: 0, riskScore: 3 },
    high: { max: 4, min: 0, riskScore: 4 }
  },
  spo2: {
    low: { max: 95, min: 93, riskScore: 2 },
    medium: { max: 92, min: 91, riskScore: 3 },
    high: { max: 90, min: 60, riskScore: 4 }
  },
  ecg: [
    { riskScore: 2, interpretation: 'SLOW' },
    { riskScore: 2, interpretation: 'Poor Waveform' },
    { riskScore: 2, interpretation: 'Lead Off' },
    { riskScore: 2, interpretation: 'High HR' },
    { riskScore: 2, interpretation: 'Low HR' },
    { riskScore: 2, interpretation: 'PVC' },
    { riskScore: 2, interpretation: 'Cardiac Arrest' },
    { riskScore: 2, interpretation: 'AFIB' },
    { riskScore: 2, interpretation: 'QRS Wide' },
    { riskScore: 2, interpretation: 'QRS Long' },
    { riskScore: 2, interpretation: 'QRS Short' },
    { riskScore: 2, interpretation: 'Irregular RR' }
  ]
};

export {};
