import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import { middlewareConfig } from '../config';
import getActimiCoreLazy from './actimi-core';

const axiosInt = axios.create({
  baseURL: middlewareConfig.baseUrl,
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache'
  },
  withCredentials: true
});

axiosInt.interceptors.request.use(async (value) => {
  const _isIncognito = await isIncognito();
  const user = localStorage.getItem('user');
  const incognitoHandled = localStorage.getItem('incognito-handled');
  if (
    _isIncognito &&
    value.url.includes('/login') &&
    !user &&
    !incognitoHandled
  ) {
    await handleIncognito(value.url);
    return value;
  }
  localStorage.removeItem('incognito-handled');
  return value;
});

axiosInt.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const accessDenied = error.toString().indexOf('401');
    if (accessDenied > -1) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      return window.location.assign('/');
    }
    return Promise.reject(
      (error.response && error.response.data) || 'There is an error!'
    );
  }
);

const handleIncognito = async (url: string) => {
  localStorage.setItem('incognito-handled', 'true');
  axiosInt.defaults.headers['x-incognito'] = true;
  const query = url.split('?')[1];
  const params = query.split('&');
  const username = params[0].split('=')[1];
  const password = params[1].split('=')[1];
  const { DoctorAuthService, isDoctorLoginResponse } =
    await getActimiCoreLazy();
  const { login } = new DoctorAuthService();
  const resp = await login(username, password);
  if (isDoctorLoginResponse(resp)) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { access_token, practitioner, role } = resp;
    localStorage.setItem('user', JSON.stringify(practitioner));
    axiosInt.defaults.headers.Authorization = access_token;
  }
};

const isIncognito = async () => {
  return new Promise(async (resolve) => {
    if ('storage' in navigator && 'estimate' in navigator.storage) {
      const { quota } = await navigator.storage.estimate();
      // 1000000000 is the estimated size of the incognito mode
      if (quota < 1000000000) {
        // Is incognito
        resolve(true);
      } else {
        // Is not incognito
        resolve(false);
      }
    } else {
      // Cannot check incognito mode, returning false as default
      resolve(false);
    }
  });
};

export const mock = new AxiosMockAdapter(axios.create(), { delayResponse: 0 });

export default axiosInt;
