import i18next from 'i18next';
import moment from 'moment';
import { TimeSpan } from '@actimi/core-migration';

export const formatDateObject = (date: string | Date): string => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

export const formatNotificationScreenHeaderDate = (
  date: string | undefined
): string => {
  if (date) {
    return moment(date).format('DD.MM.YYYY, dddd');
  }
  return '';
};

export const daysInMonth = (): number => {
  return moment().daysInMonth();
};

export const formatMeasurementCardDate = (date: string | undefined): string => {
  if (date) {
    return moment(date).format('dddd, DD MMMM YYYY | HH:mm');
  }
  return '';
};

export const formatThryveDate = (date: string | undefined): string => {
  if (date) {
    return moment(date).format('YYYY-MM-DD');
  }
  return '';
};

export const formatSavedObservationDate = (
  date: string | undefined
): string => {
  if (date) {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }
  return '';
};

export const formatDetailedGraphicCardDate = (
  date: string | undefined
): string => {
  if (date) {
    return moment(date).format('MMMM Do, h:mm a');
  }
  return '';
};

export const formatTrackCardDate = (date: string | null): string => {
  if (date != null) {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }
  return '';
};

export const formatNotificationScreenItemDate = (
  date: string | undefined
): string => {
  if (date) {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }
  return '';
};

export const formatCareTeamDate = (date: string): string => {
  if (date) {
    return moment(date).format('DD.MM.YYYY ');
  }
  return '';
};

export const formatLastMeasurementDate = (
  date: string | number
): string | Date => {
  if (typeof date === 'string' && date.includes('GMT')) {
    return moment(new Date(date)).calendar();
  }
  if (date) {
    return moment(date).calendar();
  }
  return '';
};

export const formatLastMeasurementDateWithoutAMPM = (
  date: string | number
): string | Date => {
  if (typeof date === 'string' && date.includes('GMT')) {
    if (moment(new Date(date)).calendar().split(' ').length > 4) {
      return `${moment(new Date(date))
        .calendar()
        .split(' ')
        .slice(0, 1)} ${moment(new Date(date))
        .calendar()
        .split(' ')
        .slice(1, 2)} ${moment(new Date(date))
        .calendar()
        .split(' ')
        .slice(2, 3)} ${moment(new Date(date)).format('HH:mm')}`;
    }
    return `${moment(new Date(date))
      .calendar()
      .split(' ')
      .slice(0, 1)} ${moment(new Date(date))
      .calendar()
      .split(' ')
      .slice(1, 2)} ${moment(new Date(date)).format('HH:mm')}`;
  }
  if (date) {
    return moment(date).format('HH:mm');
  }
  return '';
};

export const getTimeSpan = (timeSpan: TimeSpan): string => {
  switch (timeSpan) {
    case TimeSpan.WEEK: {
      const startWeekDay = moment().startOf('week').format('DD');
      const endWeekDay = moment().endOf('week').format('Do MMM');
      return `${startWeekDay} - ${endWeekDay}`;
    }
    case TimeSpan.MONTH: {
      const startMonthDay = moment().startOf('month').format('Do MMM');
      const endMonthDay = moment().endOf('month').format('Do MMM');
      return `${startMonthDay} - ${endMonthDay}`;
    }
    case TimeSpan.YEAR: {
      const startYearDay = moment().startOf('year').format('Do MMM YYYY');
      const endYearDay = moment().endOf('year').format('Do MMM YYYY');
      return `${startYearDay} - ${endYearDay}`;
    }
    default: {
      throw new Error('Unknown TimeSpan');
    }
  }
};

export const renderDate = (timeSpan: TimeSpan): string => {
  const today = moment();
  let dateTime;
  switch (timeSpan) {
    case TimeSpan.TODAY:
      dateTime = moment().format('dddd, D MMMM');
      return dateTime;
    case TimeSpan.MONTH:
      dateTime = `${today.format('MMMM')}, ${today.format('YYYY')}`;
      return dateTime;
    case TimeSpan.YEAR:
      dateTime = today.format('YYYY');
      return dateTime;
    case TimeSpan.WEEK:
      dateTime = `${today.startOf('week').format('D')} - ${today
        .endOf('week')
        .format('DD MMMM YYYY')}`;
      return dateTime;
    default:
      dateTime = `${moment().startOf('week').format('DD')} - ${today
        .endOf('week')
        .format('DD MMMM, YYYY')}`;
      return dateTime;
  }
};

export const renderTimeSpanDateTime = (
  timeSpan: TimeSpan,
  dateTime: string
): string => {
  switch (timeSpan) {
    case TimeSpan.TODAY:
      return moment(dateTime).locale(i18next.language).format('dddd, D MMMM');
    case TimeSpan.MONTH:
      return `${moment(dateTime)
        .locale(i18next.language)
        .subtract(29, 'days')
        .format('MMM')} ${moment(dateTime)
        .locale(i18next.language)
        .subtract(29, 'days')
        .startOf('day')
        .format('DD')}–${moment(dateTime)
        .locale(i18next.language)
        .format('MMM')} ${moment(dateTime)
        .locale(i18next.language)
        .endOf('day')
        .format('DD')}, ${moment(dateTime)
        .locale(i18next.language)
        .format('YYYY')}`;
    case TimeSpan.WEEK:
      return `${moment(dateTime)
        .locale(i18next.language)
        .format('D MMM')}–${moment(dateTime)
        .locale(i18next.language)
        .utcOffset(0)
        .add(6, 'days')
        .endOf('day')
        .format('D MMM YYYY')}`;
    case TimeSpan.YEAR:
      return `${moment(dateTime)
        .locale(i18next.language)
        .format('MMM YYYY')}–${moment(dateTime)
        .locale(i18next.language)
        .add(11, 'months')
        .format('MMM YYYY')}`;
  }
};

export const renderMinMaxDateForWeekly = (
  minDate: string,
  maxDate: string
): string => {
  return `${moment(minDate).format('DD')} - ${moment(maxDate).format(
    'DD MMMM YYYY'
  )}`;
};

export const isToday = (_date: string): boolean => {
  return new Date().toDateString() === new Date(_date).toDateString();
};

export const getUserAge = (userBirthDate: string): number => {
  return moment().diff(userBirthDate, 'years');
};

export const loadZeroToDate = (dates: string[]): string[] => {
  return dates.map((y) => {
    if (y.length === 1) {
      return `0${y}`;
    }
    return y;
  });
};

export const getFirstMondayOfMonth = (): string => {
  let date = moment()
    .set('year', new Date().getFullYear())
    .set('month', new Date().getMonth())
    .set('date', new Date().getDate())
    .isoWeekday(8);
  if (date.date() > 7) {
    date = date.isoWeekday(-6);
  }
  return date.toISOString();
};
