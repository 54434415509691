import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { Event } from 'src/models/calendar';
import { MeasureService } from 'src/utils/MeasureService';
import { TimeSpan, MeasurementTypeKey, GraphicInformation } from '@actimi/core-migration';
import {
  getGraphicDataCreatedAtParam,
  handleTimeSpanDateChange
} from 'src/utils/GraphicData';
import { isGraphDataNull } from 'src/utils/Graphic';
import moment from 'moment';

export interface IValue {
  id: string;
  value: number;
  dateTime: Date;
}

declare type IChartDataRecord = Record<MeasurementTypeKey, GraphicInformation>;
interface MeasurementState {
  events: Event[];
  isDrawerOpen: boolean;
  oxygenSaturation: GraphicInformation;
  bodyTemperature: GraphicInformation;
  bloodPressure: GraphicInformation;
  ecg: GraphicInformation;
  measurementTypeGraphData:
    | GraphicInformation
    | GraphicInformation[]
    | undefined;
  allGraphData: IChartDataRecord | undefined;
  isAllGraphDataLoading: boolean;
  previousWeekBodyWeight: GraphicInformation | undefined;
}

const initialState: MeasurementState = {
  events: [],
  isDrawerOpen: false,
  bloodPressure: {
    values: [],
    name: MeasurementTypeKey.bloodPressure,
    average: 0,
    latestMeasuredDateTime: null,
    latestMeasuredDevice: null,
    latestMeasuredValue: null,
    latestMeasuredNote: null,
    patientId: ''
  },
  bodyTemperature: {
    values: [],
    name: MeasurementTypeKey.temperature,
    average: 0,
    latestMeasuredDateTime: null,
    latestMeasuredDevice: null,
    latestMeasuredValue: null,
    latestMeasuredNote: null,
    patientId: ''
  },
  oxygenSaturation: {
    values: [],
    name: MeasurementTypeKey.bloodOxygen,
    average: 0,
    latestMeasuredDateTime: null,
    latestMeasuredDevice: null,
    latestMeasuredValue: null,
    latestMeasuredNote: null,
    patientId: ''
  },
  ecg: {
    values: [],
    name: MeasurementTypeKey.ecgHRV,
    average: 0,
    latestMeasuredDateTime: null,
    latestMeasuredDevice: null,
    latestMeasuredValue: null,
    latestMeasuredNote: null,
    patientId: ''
  },
  allGraphData: undefined,
  measurementTypeGraphData: undefined,
  isAllGraphDataLoading: true,
  previousWeekBodyWeight: undefined
};

const slice = createSlice({
  name: 'measurement',
  initialState,
  reducers: {
    fetchGraphicData(
      state: MeasurementState,
      action: PayloadAction<{
        allGraphData: GraphicInformation[] | undefined;
      }>
    ) {
      const { allGraphData } = action.payload;
      state.allGraphData = allGraphData?.reduce((data, record) => {
        console.assert(
          !(record.name in data),
          `Data for ${record.name} exists more than once in payload!`
        );
        data[record.name] = record;
        return data;
      }, {} as IChartDataRecord);
      state.isAllGraphDataLoading = false;
    },
    fetchGraphicDataByMeasurementType(
      state: MeasurementState,
      action: PayloadAction<{
        measurementTypeGraphData:
          | GraphicInformation
          | GraphicInformation[]
          | undefined;
      }>
    ) {
      const { measurementTypeGraphData } = action.payload;
      state.measurementTypeGraphData = measurementTypeGraphData;
      state.isAllGraphDataLoading = false;
    },
    fetchPrevForBodyWeight(
      state: MeasurementState,
      action: PayloadAction<{
        bodyWeightPrevData: GraphicInformation | undefined;
      }>
    ) {
      const { bodyWeightPrevData } = action.payload;
      state.previousWeekBodyWeight = bodyWeightPrevData;
      state.isAllGraphDataLoading = false;
    },
    reset(state: MeasurementState) {
      Object.assign(state, initialState);
    }
  }
});

export const { reducer } = slice;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const fetchGraphicData =
  (
    organizationId: string,
    patientId: string,
    selectedDateTime: string,
    selectedTimeSpan,
    isNext,
    t,
    whenDone: () => void,
    _createdAt?: string
  ): AppThunk =>
  async (dispatch) => {
    let allGraphData;
    const initialIsNext = selectedTimeSpan !== TimeSpan.MONTH;

    const createdAtByTimeSpan =
      selectedTimeSpan === TimeSpan.MONTH
        ? getGraphicDataCreatedAtParam(
            handleTimeSpanDateChange(
              selectedTimeSpan,
              moment
                .utc(selectedDateTime)
                .endOf('day')
                .add(1, 'day')
                .toISOString(),
              initialIsNext
            ),
            selectedDateTime
          )
        : getGraphicDataCreatedAtParam(
            moment
              .utc(selectedDateTime)
              .endOf('day')
              .add(1, 'day')
              .toISOString(),
            handleTimeSpanDateChange(
              selectedTimeSpan,
              selectedDateTime,
              initialIsNext
            )
          );

    await MeasureService.getAllGraphicData(
      organizationId,
      selectedTimeSpan,
      selectedDateTime,
      createdAtByTimeSpan,
      patientId,
      t
    ).then((values) => {
      allGraphData = values;
      dispatch(
        slice.actions.fetchGraphicData({
          allGraphData
        })
      );
      const action = whenDone;
      if (action !== undefined) {
        action();
      }
    });
  };

export const fetchGraphicDataByMeasurementType =
  (
    organizationId: string,
    measurementType: MeasurementTypeKey,
    selectedTimeSpan: TimeSpan,
    selectedDateTime: string,
    patientId: string,
    t: any,
    whenDone?: () => void,
    _createdAt?: string,
    userId?: string
  ): AppThunk =>
  async (dispatch) => {
    const initialIsNext = selectedTimeSpan !== TimeSpan.MONTH;
    const createdAtByTimeSpan =
      // eslint-disable-next-line no-nested-ternary
      selectedTimeSpan === TimeSpan.MONTH
        ? getGraphicDataCreatedAtParam(
            handleTimeSpanDateChange(
              selectedTimeSpan,
              moment
                .utc(selectedDateTime)
                .endOf('day')
                .add(1, 'day')
                .toISOString(),
              initialIsNext
            ),
            selectedDateTime
          )
        : selectedTimeSpan === TimeSpan.WEEK
        ? getGraphicDataCreatedAtParam(
            selectedDateTime,
            handleTimeSpanDateChange(
              selectedTimeSpan,
              moment
                .utc(selectedDateTime)
                .endOf('day')
                .subtract(1, 'day')
                .toISOString(),
              initialIsNext
            )
          )
        : selectedTimeSpan === TimeSpan.YEAR
        ? getGraphicDataCreatedAtParam(
            handleTimeSpanDateChange(
              selectedTimeSpan,
              moment.utc(selectedDateTime).endOf('day').toISOString(),
              initialIsNext
            ),
            moment
              .utc(selectedDateTime)
              .set({ hour: 23, minute: 59, second: 59 })
              .toISOString()
          )
        : getGraphicDataCreatedAtParam(
            selectedDateTime,
            handleTimeSpanDateChange(
              selectedTimeSpan,
              moment
                .utc(selectedDateTime)
                .endOf('day')
                .add(0, 'day')
                .toISOString(),
              initialIsNext
            )
          );

    // TODO: Remove union type somehow
    const measurementTypeGraphData =
      await MeasureService.getGraphicDataByMeasurementKey(
        organizationId,
        measurementType,
        selectedTimeSpan,
        selectedDateTime,
        createdAtByTimeSpan,
        patientId,
        t
      );

    if (measurementType === MeasurementTypeKey.bloodPressure) {
      if (isGraphDataNull(measurementTypeGraphData as GraphicInformation[])) {
        dispatch(
          slice.actions.fetchGraphicDataByMeasurementType({
            measurementTypeGraphData: []
          })
        );
      } else {
        dispatch(
          slice.actions.fetchGraphicDataByMeasurementType({
            measurementTypeGraphData
          })
        );
      }
    } else {
      dispatch(
        slice.actions.fetchGraphicDataByMeasurementType({
          measurementTypeGraphData
        })
      );
    }

    whenDone?.();
  };

export const fetchPreviousWeekForBodyWeight =
  (
    organizationId: string,
    selectedTimeSpan: TimeSpan,
    selectedDateTime: string,
    patientId: string,
    t: any,
    whenDone?: () => void,
    _createdAt?: string,
    userId?: string
  ): AppThunk =>
  async (dispatch) => {
    const initialIsNext = selectedTimeSpan !== TimeSpan.MONTH;
    const createdAtByTimeSpan =
      selectedTimeSpan === TimeSpan.MONTH
        ? getGraphicDataCreatedAtParam(
            handleTimeSpanDateChange(
              selectedTimeSpan,
              selectedDateTime,
              initialIsNext
            ),
            selectedDateTime
          )
        : getGraphicDataCreatedAtParam(
            selectedDateTime,
            handleTimeSpanDateChange(
              selectedTimeSpan,
              selectedDateTime,
              initialIsNext
            )
          );

    // TODO: Remove union type somehow
    const bodyWeightPrevData =
      (await MeasureService.getGraphicDataByMeasurementKey(
        organizationId,
        MeasurementTypeKey.bodyWeight,
        selectedTimeSpan,
        selectedDateTime,
        createdAtByTimeSpan,
        patientId,
        t
      )) as GraphicInformation;

    dispatch(
      slice.actions.fetchPrevForBodyWeight({
        bodyWeightPrevData
      })
    );

    whenDone?.();
  };

export default slice;
