import { IPatientDetail } from '@actimi/core-migration';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { AppThunk } from 'src/store';

export type PatientDetailMap = { [patientId: string]: IPatientDetail };

export type IFormState = { open: boolean; userId?: string };

export interface IPatientsDetailState {
  patientDetails: PatientDetailMap;
  patientIds: string[];
  isLoading: boolean;
  formState: IFormState;
  activeSummary: {
    shouldUpdate: boolean;
    loading: boolean;
    error?: string;
    data?: {
      active: number;
      deleted: number;
    };
  };
}

const initialState: IPatientsDetailState = {
  patientDetails: {},
  patientIds: [],
  isLoading: true,
  formState: {
    open: false,
    userId: null
  },
  activeSummary: {
    shouldUpdate: true,
    loading: false
  }
};

const slice = createSlice({
  name: 'patients-detail',
  initialState,
  reducers: {
    setPatientsDetail(
      state,
      action: PayloadAction<{
        patientDetails: PatientDetailMap;
        patientIds: string[];
      }>
    ) {
      state.patientDetails = action.payload.patientDetails;
      state.patientIds = action.payload.patientIds;
      state.isLoading = false;
    },
    setPatientDetail(
      state,
      action: PayloadAction<{
        patientDetail: IPatientDetail;
      }>
    ) {
      const { patientDetail } = action.payload;

      state.patientDetails[patientDetail.patientId] = patientDetail;
      if (!state.patientIds.find((id) => patientDetail.patientId === id))
        state.patientIds = [patientDetail.patientId, ...state.patientIds];
      state.activeSummary.shouldUpdate = true;
    },
    deletePatientDetail(state, action: PayloadAction<{ patientId: string }>) {
      const { patientId } = action.payload;
      delete state.patientDetails[patientId];
      state.patientIds.filter((id) => patientId !== id);
      state.activeSummary.shouldUpdate = true;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setFormState(state, action: PayloadAction<IFormState>) {
      state.formState = action.payload;
    },
    setActiveSummary(
      state,
      action: PayloadAction<{
        data?: { active: number; deleted: number };
        error?: string;
      }>
    ) {
      const { data, error } = action.payload;
      if (data) state.activeSummary.data = data;
      if (error) state.activeSummary.error = error;
      state.activeSummary.loading = false;
      state.activeSummary.shouldUpdate = false;
    },
    setActiveSummaryLoading(state, action: PayloadAction<boolean>) {
      state.activeSummary.loading = action.payload;
      state.activeSummary.shouldUpdate = false;
    },
    reset(state: IPatientsDetailState) {
      Object.assign(state, initialState);
    }
  }
});

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const setPatientsDetail = (patientDetailList: IPatientDetail[]) => {
  return async (dispatch) => {
    const patientDetails = _.keyBy(patientDetailList, 'patientId');
    const patientIds = patientDetailList.map(({ patientId }) => patientId);
    dispatch(slice.actions.setPatientsDetail({ patientDetails, patientIds }));
  };
};

export const updatePatientDetail = (
  patientDetail: IPatientDetail
): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setPatientDetail({ patientDetail }));
  };
};

export const { setFormState } = slice.actions;
export const { setIsLoading } = slice.actions;
export const { deletePatientDetail } = slice.actions;
export const { setActiveSummary } = slice.actions;
export const { setActiveSummaryLoading } = slice.actions;

export const { reducer } = slice;

export default slice;
