import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { Patient } from '@actimi/core-migration';
import getActimiCoreLazy from '../utils/actimi-core';

export interface AnalyticState {
  isLoading: boolean;
  patient: Patient | undefined;
}

const initialState: AnalyticState = {
  isLoading: true,
  patient: undefined
};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setActiveUser(
      state: AnalyticState,
      action: PayloadAction<{
        patient: Patient;
      }>
    ) {
      const { patient } = action.payload;
      state.patient = patient;
      state.isLoading = false;
    },
    setIsLoading(
      state: AnalyticState,
      action: PayloadAction<{
        isLoading: boolean;
      }>
    ) {
      state.isLoading = action.payload.isLoading;
    },
    setContact(
      state: AnalyticState,
      action: PayloadAction<{ contact: Patient['contact'] }>
    ) {
      const { contact } = action.payload;
      state.patient = { ...state.patient, contact };
    },
    setTelecom(
      state: AnalyticState,
      action: PayloadAction<{ telecom: Patient['telecom'] }>
    ) {
      const { telecom } = action.payload;
      state.patient = { ...state.patient, telecom };
    },
    reset(state: AnalyticState) {
      Object.assign(state, initialState);
    }
  }
});

export const { reducer } = slice;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const fetchPatient =
  (patientId: string, organizationId: string): AppThunk =>
  async (dispatch) => {
    const { PatientService } = await getActimiCoreLazy();
    const { getPatient } = new PatientService();
    const patient = await getPatient(patientId, organizationId);
    if (!patient) throw new Error('Patient not found!');
    dispatch(slice.actions.setActiveUser({ patient }));
  };

export const setIsLoading =
  (isLoading: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.setIsLoading({
        isLoading
      })
    );
  };

export const setContact =
  (contact: Patient['contact']): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setContact({ contact }));
  };

export const setTelecom =
  (telecom: Patient['telecom']): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setTelecom({ telecom }));
  };
export default slice;
