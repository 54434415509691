import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';

import { Scrollbars } from 'react-custom-scrollbars-2';
import { Box, useTheme } from '@mui/material';

interface ScrollbarProps {
  className?: string;
  children?: ReactNode;
  onScrollStarted?: () => void;
  onScrollEnd?: () => void;
}

const Scrollbar: FC<ScrollbarProps> = ({
  className,
  children,
  onScrollStarted,
  onScrollEnd,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Scrollbars
      autoHide
      universal
      onScrollStart={() => onScrollStarted()}
      onScrollStop={() => onScrollEnd()}
      renderThumbVertical={() => {
        return (
          <Box
            sx={{
              width: 5,
              background: `${theme.palette.common.black[100]}`,
              borderRadius: `10px`,

              '&:hover': {
                background: `${theme.palette.common.black[30]}`
              }
            }}
          />
        );
      }}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
};

Scrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Scrollbar;
