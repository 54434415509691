import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

const ConfirmationDialog = ({
  open,
  options,
  onCancel,
  onConfirm,
  onClose
}) => {
  const {
    title,
    description,
    confirmationText,
    cancellationText,
    hideCancel,
    allowClose,
    confirmationButtonProps
  } = options;

  return (
    <Dialog fullWidth open={open} onClose={allowClose ? onClose : null}>
      {title && <DialogTitle>{title}</DialogTitle>}

      {description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        {!hideCancel && <Button onClick={onCancel}>{cancellationText}</Button>}
        <Button
          color="primary"
          variant="contained"
          {...confirmationButtonProps}
          onClick={onConfirm}
        >
          {confirmationText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
