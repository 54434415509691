import { Link } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';

const LogoWrapper = experimentalStyled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function LogoCardio() {
  return (
    <LogoWrapper to="/">
      {/* <LogoSignWrapper> */}
      <img
        alt=""
        src="/static/images/logo/actimi-telecare.png"
        style={{
          width: 140
        }}
      />
    </LogoWrapper>
  );
}

export default LogoCardio;
