/* eslint-disable eqeqeq */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { IConditionsInfo } from 'src/models/medical-info/IMedicalInfo';
import type { AppThunk } from 'src/store';
import getActimiCoreLazy from 'src/utils/actimi-core';

export interface ConditionState {
  selectedCondition: IConditionsInfo | null;
  allConditions: IConditionsInfo[];
}

const initialState: ConditionState = {
  allConditions: [],
  selectedCondition: null
};

const slice = createSlice({
  name: 'conditions',
  initialState,
  reducers: {
    reset(state: ConditionState) {
      Object.assign(state, initialState);
    },
    getConditions(
      state: ConditionState,
      action: PayloadAction<{
        conditions: IConditionsInfo[];
      }>
    ) {
      const { conditions } = action.payload;
      state.allConditions = conditions;
    },
    createCondition(
      state: ConditionState,
      action: PayloadAction<{
        condition: IConditionsInfo;
      }>
    ) {
      const { condition } = action.payload;
      state.allConditions.push(condition);
    },
    updateCondition(
      state: ConditionState,
      action: PayloadAction<{
        condition: IConditionsInfo;
      }>
    ) {
      const { condition } = action.payload;
      const index = state.allConditions.findIndex((x) => x.id === condition.id);
      state.allConditions[index] = condition;
    }
  }
});

export const { reducer } = slice;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const getConditions =
  (
    patientId: string,
    subjectOrganizationId: string,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch) => {
    const { ConditionService } = await getActimiCoreLazy();
    const conditionService = new ConditionService();
    const _conditions = await conditionService.findAll(subjectOrganizationId, {
      subject: patientId
    });
    const conditions: IConditionsInfo[] = _conditions.map((x) => ({
      id: x.id,
      condition: x.code.coding[0]?.display,
      code: x.code.coding[0]?.code,
      creationDate: x.recordedDate,
      practitionerId: x.recorder?.id,
      practitionerName: '',
      patientId
    }));
    dispatch(slice.actions.getConditions({ conditions }));
    whenDone?.();
  };

export const updateCondition =
  (
    _condition: Pick<IConditionsInfo, 'id' | 'condition'>,
    subjectOrganizationId: string,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch) => {
    const { condition, id } = _condition;
    const { ConditionService } = await getActimiCoreLazy();
    const conditionService = new ConditionService();
    const updated = await conditionService.update(id, subjectOrganizationId, {
      coding: { code: '0', display: condition }
    });
    const newConditionFormatted = {
      id: updated.id,
      condition: updated.code.coding[0]?.display,
      code: updated.code.coding[0]?.code,
      creationDate: updated.recordedDate,
      practitionerId: updated.recorder?.id,
      practitionerName: '',
      patientId: updated?.subject?.id
    };
    dispatch(
      slice.actions.updateCondition({ condition: newConditionFormatted })
    );
    whenDone?.();
  };

export const createCondition =
  (
    _condition: IConditionsInfo,
    subjectOrganizationId: string,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch) => {
    const { condition, patientId, practitionerId } = _condition;
    const { ConditionService } = await getActimiCoreLazy();
    const conditionService = new ConditionService();
    const newCondition = await conditionService.create(
      {
        coding: { code: '0', display: condition },
        subject: patientId,
        recorder: practitionerId
      },
      subjectOrganizationId
    );
    const newConditionFormatted = {
      id: newCondition.id,
      condition: newCondition.code.coding[0]?.display,
      code: newCondition.code.coding[0]?.code,
      creationDate: newCondition.recordedDate,
      practitionerId: newCondition.recorder?.id,
      practitionerName: '',
      patientId: newCondition?.subject?.id
    };
    dispatch(
      slice.actions.createCondition({ condition: newConditionFormatted })
    );
    whenDone?.();
  };
