import { useContext } from 'react';

import {
  Box,
  IconButton,
  Tooltip,
  styled,
  Typography
} from '@material-ui/core';
import MenuTwoToneIcon from '@material-ui/icons/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';

// import HeaderMenu from './Menu';
import JustCardioTextLogo from 'src/components/JustCardioTextLogo';
import { useSelector } from 'src/store';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HeaderUserbox from './Userbox';
import HeaderButtons from './Buttons';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        top: 0;
        z-index: 6;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.md}px) {
            left: ${theme.spacing(12)};
            width: auto;
        }
`
);

function Header() {
  const { t }: { t: any } = useTranslation();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const { authPractitionerInfo } = useSelector((state) => state.clinic);

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box display="flex" alignItems="center">
        <Box
          component="span"
          sx={{
            display: { md: 'none', xs: 'inline-block' }
          }}
        >
          <JustCardioTextLogo />
        </Box>
        {authPractitionerInfo === undefined ? (
          <Box
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'flex'
              }
            }}
          >
            <Skeleton width={80} animation="wave" />
            <Skeleton width={120} animation="wave" />
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'flex'
              }
            }}
          >
            <Typography variant="h4">
              {authPractitionerInfo?.clinic.name}
            </Typography>
            <Typography variant="subtitle1">
              {t('Clinic ID')}:{' '}
              {authPractitionerInfo?.clinic?.identifier?.[0]?.value}
            </Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        <HeaderButtons />
        <HeaderUserbox />
        <Box
          component="span"
          sx={{
            display: { md: 'none', xs: 'inline-block' }
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
