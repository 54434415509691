/* eslint-disable eqeqeq */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { IAllergiesInfo } from 'src/models/medical-info/IMedicalInfo';
import type { AppThunk } from 'src/store';
import { AllergyCriticality, AllergyType } from '@actimi/core-migration';
import getActimiCoreLazy from '../utils/actimi-core';

export interface AllergiesState {
  selectedAllergy: IAllergiesInfo | null;
  allAllergies: IAllergiesInfo[];
}

const initialState: AllergiesState = {
  allAllergies: [],
  selectedAllergy: null
};

const slice = createSlice({
  name: 'allergies',
  initialState,
  reducers: {
    reset(state: AllergiesState) {
      Object.assign(state, initialState);
    },
    getAllergies(
      state: AllergiesState,
      action: PayloadAction<{
        allergies: IAllergiesInfo[];
      }>
    ) {
      const { allergies } = action.payload;
      state.allAllergies = allergies;
    },
    createAllergy(
      state: AllergiesState,
      action: PayloadAction<{
        allergy: IAllergiesInfo;
      }>
    ) {
      const { allergy } = action.payload;
      state.allAllergies.push(allergy);
    }
  }
});

export const { reducer } = slice;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const getAllergies =
  (
    patientId: string,
    patientOrganizationId: string,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch) => {
    const { AllergyService } = await getActimiCoreLazy();
    const allergyService = new AllergyService();
    const _allergies = await allergyService.findAll(
      patientId,
      patientOrganizationId
    );
    const allergies: IAllergiesInfo[] = _allergies.map((x) => ({
      id: x.id,
      allergy: x.code.coding[0]?.display,
      code: x.code.coding[0]?.code,
      creationDate: x.recordedDate,
      practitionerId: x.recorder?.id,
      practitionerName: '',
      patientId
    }));
    dispatch(slice.actions.getAllergies({ allergies }));
    whenDone?.();
  };

export const createAllergy =
  (
    _allergy: IAllergiesInfo,
    patientOrganizationId: string,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch) => {
    const { allergy, patientId, practitionerId, creationDate } = _allergy;
    const { AllergyService } = await getActimiCoreLazy();
    const allergyService = new AllergyService();
    await allergyService.create(
      {
        patient: patientId,
        recorder: practitionerId,
        criticality: AllergyCriticality.Low,
        type: AllergyType.Allergy,
        coding: {
          code: '0',
          display: allergy,
          system: 'http://snomed.info/sct'
        },
        recordedDate: creationDate ?? new Date().toISOString()
      },
      patientOrganizationId
    );
    dispatch(slice.actions.createAllergy({ allergy: _allergy }));
    whenDone?.();
  };
