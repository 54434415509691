import type { ReactNode } from 'react';
import BarChartIcon from '@material-ui/icons/BarChart';
import SmsIcon from '@material-ui/icons/Forum';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LogsIcon from '@material-ui/icons/Article';
import { FaHospital } from 'react-icons/fa';
import { Action } from '../../../../services/casl/Action';
import { SubjectResources } from '../../../../services/casl/subjects/SubjectResources';
import { ResourceTypes } from '@actimi/core-migration';

// import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

export interface Ability {
  // Can I *action* e.g. Create, Read, Update, Delete
  i: Action;
  // Can I *action* a *subject* e.g. User, Patient, Questionnaire
  a: ResourceTypes;
}

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  ability?: Ability;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'Dashboard',
    items: [
      {
        name: 'Overview',
        icon: BarChartIcon,
        link: '/dashboards/main-dashboard'
      },
      {
        name: 'Patients',
        icon: PeopleAltIcon,
        ability: { i: Action.S, a: SubjectResources.Patient },
        link: '/dashboards/patients'
      },
      {
        name: 'Chat',
        icon: SmsIcon,
        ability: { i: Action.C, a: SubjectResources.Messages },
        link: '/applications/messenger'
      },
      {
        name: 'Settings',
        icon: FaHospital,
        link: '/dashboards/settings'
      }
      // {
      //   name: 'Logs',
      //   icon: LogsIcon,
      //   ability: { i: Action.R, a: SubjectResources.AuditEvent },
      //   link: '/applications/log-history'
      // }
      // {
      //   name: 'Notifications',
      //   icon: NotificationsActiveIcon,
      //   link: '/dashboards/notifications'
      // },
      // {
      //   name: "Todo's",
      //   icon: GradingIcon,
      //   link: '/applications/communication-review'
      // },
    ]
  }
];

export default menuItems;
