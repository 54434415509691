import {
  BloodPressureCode,
  ObservationCode,
  MeasurementTypeKey
} from '@actimi/core-migration';
import { formatLastMeasurementDateWithoutAMPM } from './DateTimeFormatters';

export const getObservationCodeWithMeasureTypeKey = (
  measurementTypeKey: MeasurementTypeKey
): ObservationCode | BloodPressureCode => {
  switch (measurementTypeKey) {
    case MeasurementTypeKey.temperature:
      return ObservationCode.BODY_TEMPERATURE;
    case MeasurementTypeKey.bloodGlucose:
      return ObservationCode.BLOOD_GLUCOSE;
    case MeasurementTypeKey.ecgHRV:
      return ObservationCode.HEARTH_RATE_VARIABILITY;
    case MeasurementTypeKey.mood:
      return ObservationCode.MOOD;
    case MeasurementTypeKey.bloodOxygen:
      return ObservationCode.OXYGEN_SATURATION;
    case MeasurementTypeKey.bodyWeight:
      return ObservationCode.BODY_WEIGHT;
    case MeasurementTypeKey.bodyHeight:
      return ObservationCode.BODY_HEIGHT;
    case MeasurementTypeKey.gratitude:
      return ObservationCode.GRATITUDE;
    case MeasurementTypeKey.bloodPressure:
      return {
        sys: ObservationCode.SYSTOLIC_BLOOD_PRESSURE,
        dias: ObservationCode.DIASTOLIC_BLOOD_PRESSURE,
        heartRate: ObservationCode.BLOOD_PRESSURE_HEART_RATE
      };
    default:
      throw new Error('unknown observation code');
  }
};

export const getLatestMeasurementDateText = (
  lastMeasureDateTime: string | number | null
): string | undefined => {
  let resultText = ' ';
  if (lastMeasureDateTime !== null) {
    resultText += formatLastMeasurementDateWithoutAMPM(lastMeasureDateTime);
    return resultText;
  }
  return undefined;
};
