import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { IUserProfile } from '@actimi/core-migration';

export type PatientProfileMap = { [patientId: string]: IUserProfile };

export interface IPatientsProfileState {
  patients: PatientProfileMap;
  patientIds: string[];
  isLoading: boolean;
}

const initialState: IPatientsProfileState = {
  patients: {},
  patientIds: [],
  isLoading: true
};

const slice = createSlice({
  name: 'patients-profile',
  initialState,
  reducers: {
    setPatients(
      state,
      action: PayloadAction<{
        patients: PatientProfileMap;
      }>
    ) {
      state.patients = action.payload.patients;
      state.patientIds = Object.keys(state.patients);
      state.isLoading = false;
    },
    setPatient(
      state,
      action: PayloadAction<{
        patient: IUserProfile;
      }>
    ) {
      const { patient } = action.payload;

      state.patients[patient.id] = patient;
      state.patientIds = Object.keys(state.patients);
    },
    setIsLoading(
      state,
      action: PayloadAction<{
        isLoading: boolean;
      }>
    ) {
      state.isLoading = action.payload.isLoading;
    },
    reset(state: IPatientsProfileState) {
      Object.assign(state, initialState);
    }
  }
});

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const setPatients = (patientsList: IUserProfile[]) => {
  return async (dispatch) => {
    const patients = patientsList.reduce((data: PatientProfileMap, patient) => {
      data[patient.id] = patient;
      return data;
    }, {});
    dispatch(slice.actions.setPatients({ patients }));
  };
};

export const updatePatient =
  (patient: IUserProfile): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setPatient({ patient }));
  };

export const { reducer } = slice;

export default slice;
