/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import useScrollTop from 'src/hooks/useScrollTop';
import useAuth from 'src/hooks/useAuth';
import { CssBaseline } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import { chatSocket, socket } from 'src/utils/socket';
import routes from './router';
import ThemeProvider from './theme/ThemeProvider';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from './store';
import { UpdateSubscriptionSocket } from './utils/UpdateSubscriptionSocket';
import { setPatients } from './slices/patients-profile';
import { setLimitValue } from './slices/limit-values';
import { resetActions } from './store/rootReducer';
import { AbilityContext } from './services/casl/AbilityContext';
import { getAuthPractitionerInfo, getClinicMembers } from './slices/clinic';
import getActimiCoreLazy from './utils/actimi-core';
import axiosInt from './utils/axios';

// Initialize axios api
(async () => {
  getActimiCoreLazy().then(({ initAxiosInstance }) => {
    initAxiosInstance(axiosInt);
  });
})();

const App = () => {
  const content = useRoutes(routes);
  const auth = useAuth();
  useScrollTop();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (auth.user?.id) {
        // const { ChatSocketService } = await getActimiCoreLazy();
        // const { setActiveStatus } = new ChatSocketService(socket);
        dispatch(getAuthPractitionerInfo(auth.userId));
        return () => {
          // setActiveStatus(auth.userId, false);
        };
      }
    })();

    if (auth.user?.id) {
      socket.connect();
    } else {
      socket.close();
    }
  }, [auth.user?.id]);

  useEffect(() => {
    if (!auth.user?.clinic?.id) return;
    (async () => {
      const { PatientService } = await getActimiCoreLazy();
      const { getPatientProfilesWithLimitValues } = new PatientService();
      const { limitValues, patients } = await getPatientProfilesWithLimitValues(
        auth.user.clinic.id,
        '3000'
      );
      // @ts-ignore
      dispatch(setPatients(patients));
      dispatch(setLimitValue(limitValues));
      dispatch(getClinicMembers(auth.user.clinic.id));
    })();
    return () => {
      // Cleanup store by using each slice's reset action
      resetActions.forEach((resetAction) => dispatch(resetAction()));

      // Remove all listeners in socket.
      UpdateSubscriptionSocket.cleanUp();
    };
  }, [auth.user?.clinic?.id]);

  // (
  //   <Jitsi
  //     config={{ startAudioOnly: false, startScreenSharing: true }}
  //     domain="meet.jit.si"
  //     roomName={selectedChat.name.split(' ')[0]}
  //     displayName={auth.user.name}
  //     onAPILoad={(JitsiMeetAPI) => {
  //       // @ts-ignore
  //       JitsiMeetApi.setApi(JitsiMeetAPI);
  //     }}
  //   />
  // )
  return (
    <>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AbilityContext.abilityCtx.Provider value={AbilityContext.ability}>
            <CssBaseline />
            {auth.isInitialized ? (
              <>
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                {content}
              </>
            ) : (
              <>auth not initialized</>
            )}
          </AbilityContext.abilityCtx.Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  );
};
export default App;
