import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateClinicMember,
  Doctor,
  IClinic,
  IClinicMember,
  Role
} from '@actimi/core-migration';
import axiosInt from 'src/utils/axios';
import { AppDispatch, AppThunk } from '../store';
import getActimiCoreLazy from '../utils/actimi-core';

export interface ClinicState {
  authPractitionerInfo: Doctor | undefined;
  practitionerInfo: Doctor | undefined;
  clinicInfo: IClinic | undefined;
  clinicMembers: IClinicMember[];
}

const initialState: ClinicState = {
  authPractitionerInfo: undefined,
  practitionerInfo: undefined,
  clinicInfo: undefined,
  clinicMembers: []
};
const slice = createSlice({
  name: 'clinic',
  initialState,
  reducers: {
    getAuthPractitionerInfo(
      state: ClinicState,
      action: PayloadAction<{ authPractitionerInfo: Doctor }>
    ) {
      const { authPractitionerInfo } = action.payload;
      state.authPractitionerInfo = authPractitionerInfo;
    },
    getPractitionerInfo(
      state: ClinicState,
      action: PayloadAction<{ practitionerInfo: Doctor }>
    ) {
      const { practitionerInfo } = action.payload;
      state.practitionerInfo = practitionerInfo;
    },
    getClinicInfo(
      state: ClinicState,
      action: PayloadAction<{ clinicInfo: IClinic }>
    ) {
      const { clinicInfo } = action.payload;
      state.clinicInfo = clinicInfo;
    },
    setClinicMembers(
      state,
      action: PayloadAction<{
        clinicMembers: IClinicMember[];
      }>
    ) {
      state.clinicMembers = action.payload.clinicMembers;
    },
    updateClinicMember(
      state,
      action: PayloadAction<{
        clinicMember: IClinicMember;
      }>
    ) {
      const { clinicMember } = action.payload;
      const maybeOldClinicMember = state.clinicMembers.find(
        ({ practitioner }) => practitioner.id === clinicMember.practitioner.id
      );
      if (maybeOldClinicMember) {
        Object.assign(maybeOldClinicMember, clinicMember);
      } else {
        state.clinicMembers.push(clinicMember);
      }
    },
    removeClinicMember(
      state,
      action: PayloadAction<{
        userId: string;
      }>
    ) {
      const index = state.clinicMembers.findIndex(
        (member) => member.role?.user?.id === action.payload.userId
      );
      if (index !== -1) {
        state.clinicMembers.splice(index, 1);
      }
    },
    reset(state: ClinicState) {
      Object.assign(state, initialState);
    }
  }
});

// Reducers

export const { reducer } = slice;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const getAuthPractitionerInfo =
  (authUserId: string, whenDone?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { PractitionerService } = await getActimiCoreLazy();
    const { getPractitionerInfo: getPractitionerInfoAPI } =
      new PractitionerService();

    const { data: roles } = await axiosInt.get<Role[]>(
      `/Role?user=${authUserId}&_result=array`
    );
    const role = roles[0];
    if (!role?.links?.practitioner?.id || !role.links.organization?.id) {
      throw new Error('You have no role binding to any organization!');
    }
    const resp = await getPractitionerInfoAPI(
      role.links.practitioner.id,
      role.links.organization.id
    );
    dispatch(
      slice.actions.getAuthPractitionerInfo({ authPractitionerInfo: resp })
    );
    whenDone?.();
  };

export const getPractitionerInfo =
  (userId: string, whenDone?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { PractitionerService } = await getActimiCoreLazy();
    const { getPractitionerInfo: getPractitionerInfoAPI } =
      new PractitionerService();

    const { data: roles } = await axiosInt.get<Role[]>(
      `/Role?user=${userId}&_result=array`
    );
    const role = roles[0];
    if (!role?.links?.practitioner?.id || !role.links.organization?.id) {
      throw new Error('You have no role binding to any organization!');
    }
    const resp = await getPractitionerInfoAPI(
      role.links.practitioner.id,
      role.links.organization.id
    );
    dispatch(slice.actions.getPractitionerInfo({ practitionerInfo: resp }));
    whenDone?.();
  };

export const getClinicInfo =
  (clinicId: string, whenDone?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { ClinicService } = await getActimiCoreLazy();
    const { getClinicInfo: getClinicInfoAPI } = new ClinicService();

    const resp = await getClinicInfoAPI(clinicId);
    dispatch(slice.actions.getClinicInfo({ clinicInfo: resp }));
    whenDone?.();
  };

export const deletePractitionerAccount =
  (
    data: { userId: string; organizationId: string },
    callbacks: Partial<Record<'onSuccess' | 'onError', Function>> = {}
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { PractitionerService } = await getActimiCoreLazy();
    const { deletePractitioner: deletePractitionerAPI } =
      new PractitionerService();

    await deletePractitionerAPI(data)
      .then(() => {
        callbacks.onSuccess?.();
        dispatch(slice.actions.removeClinicMember({ userId: data.userId }));
      })
      .catch(() => callbacks.onError?.());
  };

export const getClinicMembers =
  (clinicId: string): AppThunk =>
  async (dispatch) => {
    const { ClinicService } = await getActimiCoreLazy();
    const { getClinicMembers: getClinicMembersAPI } = new ClinicService();

    const resp = await getClinicMembersAPI(clinicId);

    dispatch(slice.actions.setClinicMembers({ clinicMembers: resp }));
  };

export const inviteMembersToClinic =
  (membersArr: CreateClinicMember[], clinicId: string): AppThunk =>
  async () => {
    const { ClinicService } = await getActimiCoreLazy();
    const { inviteMembersToClinic: inviteMembersToClinicAPI } =
      new ClinicService();

    await inviteMembersToClinicAPI(clinicId, membersArr);
  };

export const updateClinicMember =
  (clinicMember: IClinicMember): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateClinicMember({ clinicMember }));
  };

export default slice;
