import { io } from 'socket.io-client';
import { middlewareConfig } from '../config';
import axiosInt from './axios';

export const socket = io(
  `${middlewareConfig.baseUrl}/sub-subscription-socket`,
  {
    withCredentials: true,
    autoConnect: false,
    forceNew: true,
    auth: (cb) =>
      cb({ bearerToken: axiosInt.defaults?.headers?.common?.Authorization })
  }
);

export const chatSocket = io(`${middlewareConfig.baseUrl}/chat-socket`, {
  withCredentials: true,
  autoConnect: false,
  forceNew: true,
  auth: (cb) =>
    cb({ bearerToken: axiosInt.defaults?.headers?.common?.Authorization })
});
