import {
  GraphicInformation,
  ObservationCode,
  MeasurementTypeKey,
  GraphicWaveValues,
  IGetGraphicsInformationResponse,
  isMultipleGraphDataResponse,
  LatestMeasurementsValue,
  BloodPressureCode,
  TimeSpan,
  ObservationService,
  QuestionnaireService,
  QuestionnaireResponse,
  QuestionnaireTypes,
  ILatestMeasureWithCodeResponse,
  EcgDiagnosticFlag
} from '@actimi/core-migration';
import { getObservationCodeWithMeasureTypeKey } from './Measure';
import { formatGraphicData } from './GraphicData';

export class MeasureService {
  public static async getAllGraphicData(
    organizationId: string,
    selectedTimeSpan: TimeSpan,
    selectedDateTime: string,
    _createdAt?: string,
    userId?: string,
    t?: any
  ): Promise<GraphicInformation | GraphicInformation[]> {
    const { getAllGraphicInformation } = new ObservationService();
    const queries = [
      ObservationCode.SYSTOLIC_BLOOD_PRESSURE,
      ObservationCode.DIASTOLIC_BLOOD_PRESSURE,
      ObservationCode.HEART_RATE,
      ObservationCode.OXYGEN_SATURATION,
      ObservationCode.BODY_WEIGHT,
      ObservationCode.SYMPTOM,
      ObservationCode.ECG
    ].map((code) => ({
      code,
      timespan: selectedTimeSpan,
      subject: `Patient/${userId}`,
      _createdAt
    }));
    const fetchedGraphicData = await getAllGraphicInformation(
      organizationId,
      queries,
      { [ObservationCode.ECG]: 1 }
    );

    return [
      formatGraphicData(
        fetchedGraphicData?.[0],
        selectedTimeSpan,
        selectedDateTime,
        t
      ),
      formatGraphicData(
        fetchedGraphicData?.[1],
        selectedTimeSpan,
        selectedDateTime,
        t
      ),
      formatGraphicData(
        fetchedGraphicData?.[2],
        selectedTimeSpan,
        selectedDateTime,
        t
      ),
      formatGraphicData(
        fetchedGraphicData?.[3],
        selectedTimeSpan,
        selectedDateTime,
        t
      ),
      formatGraphicData(
        fetchedGraphicData?.[4],
        selectedTimeSpan,
        selectedDateTime,
        t
      ),
      formatGraphicData(
        fetchedGraphicData?.[5],
        selectedTimeSpan,
        selectedDateTime,
        t
      ),
      formatGraphicData(
        fetchedGraphicData?.[6],
        selectedTimeSpan,
        selectedDateTime,
        t
      )
    ];
  }

  public static async getGraphicDataByMeasurementKey(
    organizationId: string,
    measurementTypeKey: MeasurementTypeKey,
    selectedTimeSpan: TimeSpan,
    selectedDateTime: string,
    _createdAt?: string,
    userId?: string,
    t?: any
  ): Promise<GraphicInformation | GraphicInformation[]> {
    const { getGraphicInformation } = new ObservationService();

    let fetchedGraphicData:
      | GraphicInformation
      | [GraphicInformation, GraphicInformation, GraphicInformation];
    switch (measurementTypeKey) {
      case MeasurementTypeKey.bloodOxygen:
        fetchedGraphicData = (await getGraphicInformation(organizationId, {
          code: ObservationCode.OXYGEN_SATURATION,
          timespan: selectedTimeSpan,
          _createdAt,
          subject: `Patient/${userId}`
        })) as GraphicInformation;
        break;
      case MeasurementTypeKey.bodyWeight:
        fetchedGraphicData = (await getGraphicInformation(organizationId, {
          code: ObservationCode.BODY_WEIGHT,
          timespan: selectedTimeSpan,
          _createdAt,
          subject: `Patient/${userId}`
        })) as GraphicInformation;
        break;
      case MeasurementTypeKey.heartRate:
        fetchedGraphicData = (await getGraphicInformation(organizationId, {
          code: ObservationCode.HEART_RATE,
          timespan: selectedTimeSpan,
          _createdAt,
          subject: `Patient/${userId}`
        })) as GraphicInformation;
        break;
      case MeasurementTypeKey.symptomQuestionnaire:
        fetchedGraphicData = (await getGraphicInformation(organizationId, {
          code: ObservationCode.SYMPTOM,
          timespan: selectedTimeSpan,
          _createdAt,
          subject: `Patient/${userId}`
        })) as GraphicInformation;
        break;
      case MeasurementTypeKey.ecg:
        fetchedGraphicData = (await getGraphicInformation(organizationId, {
          code: ObservationCode.ECG,
          timespan: selectedTimeSpan,
          _createdAt,
          subject: `Patient/${userId}`
        })) as GraphicInformation;
        break;
      case MeasurementTypeKey.bloodPressure:
        fetchedGraphicData = await Promise.all([
          getGraphicInformation(organizationId, {
            code: ObservationCode.SYSTOLIC_BLOOD_PRESSURE,
            timespan: selectedTimeSpan,
            subject: `Patient/${userId}`,
            _createdAt
          }) as Promise<GraphicInformation>,
          getGraphicInformation(organizationId, {
            code: ObservationCode.DIASTOLIC_BLOOD_PRESSURE,
            timespan: selectedTimeSpan,
            subject: `Patient/${userId}`,
            _createdAt
          }) as Promise<GraphicInformation>,
          getGraphicInformation(organizationId, {
            code: ObservationCode.BLOOD_PRESSURE_HEART_RATE,
            timespan: selectedTimeSpan,
            subject: `Patient/${userId}`,
            _createdAt
          }) as Promise<GraphicInformation>
        ]);
        break;
      default:
        throw new Error('Unknown measure type key');
    }
    if (isMultipleGraphDataResponse(fetchedGraphicData)) {
      return [
        formatGraphicData(
          fetchedGraphicData[0],
          selectedTimeSpan,
          selectedDateTime,
          t
        ),
        formatGraphicData(
          fetchedGraphicData[1],
          selectedTimeSpan,
          selectedDateTime,
          t
        ),
        formatGraphicData(
          fetchedGraphicData[2],
          selectedTimeSpan,
          selectedDateTime,
          t
        )
      ];
    }
    return formatGraphicData(
      fetchedGraphicData,
      selectedTimeSpan,
      selectedDateTime,
      t
    );
  }

  public static getGraphicInformationLatestMeasurements(
    data: IGetGraphicsInformationResponse
  ): Omit<LatestMeasurementsValue, 'mood'> {
    return {
      bodyTemperature: {
        bodyTemperature: Number(
          data.temperatureGraphicData.latestMeasuredValue
        ),
        lastMeasuredTime: data.temperatureGraphicData.latestMeasuredDateTime,
        device: data.temperatureGraphicData.latestMeasuredDevice,
        note: data.temperatureGraphicData.latestMeasuredNote,
        riskScore: undefined
      },
      heartRate: {
        heartRate: Number(data.ecgHeartRateGraphicData.latestMeasuredValue),
        lastMeasuredTime: data.ecgHeartRateGraphicData.latestMeasuredDateTime,
        device: data.ecgHeartRateGraphicData.latestMeasuredDevice,
        note: data.ecgHeartRateGraphicData.latestMeasuredNote
      },
      bodyWeight: {
        bodyWeight: Number(data.bodyWeightGraphicData.latestMeasuredValue),
        lastMeasuredTime: data.bodyWeightGraphicData.latestMeasuredDateTime,
        note: data.bodyWeightGraphicData.latestMeasuredNote,
        device: data.bodyWeightGraphicData.latestMeasuredDevice,
        riskScore: undefined
      },
      bloodGlucose: {
        bloodGlucose: Number(data.bloodGlucoseGraphicData.latestMeasuredValue),
        lastMeasuredTime: data.bloodGlucoseGraphicData.latestMeasuredDateTime,
        note: data.bloodGlucoseGraphicData.latestMeasuredNote,
        device: data.bloodGlucoseGraphicData.latestMeasuredDevice,
        riskScore: undefined
      },
      bodyHeight: {
        bodyHeight: Number(data.bodyHeightGraphicData.latestMeasuredValue),
        lastMeasuredTime: data.bodyHeightGraphicData.latestMeasuredDateTime,
        note: data.bodyHeightGraphicData.latestMeasuredNote,
        device: data.bodyHeightGraphicData.latestMeasuredDevice
      },
      // symptoms: {
      //   symptoms: Number(data.bodyHeightGraphicData.latestMeasuredValue),
      //   lastMeasuredTime: data.bodyHeightGraphicData.latestMeasuredDateTime,
      //   note: data.bodyHeightGraphicData.latestMeasuredNote,
      //   device: data.bodyHeightGraphicData.latestMeasuredDevice
      // },
      bloodPressure: {
        heartRate:
          data.bloodPressureGraphicData !== undefined
            ? Number(data.bloodPressureGraphicData[2].latestMeasuredValue)
            : 0,
        systolic:
          data.bloodPressureGraphicData !== undefined
            ? Number(data.bloodPressureGraphicData[0].latestMeasuredValue)
            : 0,
        diastolic:
          data.bloodPressureGraphicData !== undefined
            ? Number(data.bloodPressureGraphicData[0].latestMeasuredValue)
            : 0,
        note: data.bloodPressureGraphicData[0].latestMeasuredNote,
        device: data.bloodPressureGraphicData[0].latestMeasuredDevice,
        lastMeasuredTime:
          data.bloodPressureGraphicData[0].latestMeasuredDateTime,
        riskScore: undefined
      },
      spO2: {
        spo2: Number(data.bloodOxygenGraphicData.latestMeasuredValue),
        lastMeasuredTime: data.bloodOxygenGraphicData.latestMeasuredDateTime,
        note: data.bloodOxygenGraphicData.latestMeasuredNote,
        device: data.bloodOxygenGraphicData.latestMeasuredDevice,
        riskScore: undefined
      },
      // mood: {
      //   mood:
      //     data.moodGraphicData !== undefined
      //       ? data.moodGraphicData.latestMeasuredValue !== null
      //         ? data.moodGraphicData.latestMeasuredValue.toString()
      //         : ''
      //       : '',
      //   lastMeasuredTime:
      //     data.moodGraphicData !== undefined
      //       ? data.moodGraphicData.latestMeasuredDateTime
      //       : null,
      //   feelings: undefined,
      //   activities: undefined,
      //   note: data.moodGraphicData?.latestMeasuredNote,
      //   device: data.moodGraphicData?.latestMeasuredDevice
      // },
      ecg: {
        heartRate:
          data.ecgHeartRateGraphicData !== undefined
            ? Number(data.ecgHeartRateGraphicData.latestMeasuredValue)
            : 0,
        hrv:
          data.hrvGraphicData !== undefined
            ? Number(data.hrvGraphicData.latestMeasuredValue)
            : 0,
        lastMeasuredTime:
          data.hrvGraphicData !== undefined
            ? data.hrvGraphicData.latestMeasuredDateTime
            : null,
        rrMax: 0,
        rrMin: 0,
        breathRate: 0,
        // TODO: Implement
        interpretation: EcgDiagnosticFlag.REGULAR,
        mood: 0,
        riskScore: undefined,
        note: data.ecgHeartRateGraphicData.latestMeasuredNote,
        device: data.ecgHeartRateGraphicData.latestMeasuredDevice
      }
    };
  }

  public static async getLatestMeasuredWithMeasureKey(
    measurementTypeKey: MeasurementTypeKey
  ): Promise<ILatestMeasureWithCodeResponse | undefined> {
    const observationCode =
      getObservationCodeWithMeasureTypeKey(measurementTypeKey);
    const { getLatestMeasurementsWithCode } = new ObservationService();

    if (measurementTypeKey === MeasurementTypeKey.bloodPressure) {
      const sysData = await getLatestMeasurementsWithCode(
        // must be fixed
        'UserState.currentUser.id',
        (observationCode as BloodPressureCode).sys
      );
      const diasData = await getLatestMeasurementsWithCode(
        // must be fixed
        'UserState.currentUser.id',
        (observationCode as BloodPressureCode).dias
      );
      const bpHeartRateData = await getLatestMeasurementsWithCode(
        // must be fixed
        'UserState.currentUser.id',
        (observationCode as BloodPressureCode).heartRate
      );
      if (
        sysData !== undefined &&
        diasData !== undefined &&
        bpHeartRateData !== undefined
      ) {
        const data: ILatestMeasureWithCodeResponse = {
          lastMeasuredValue: {
            sys:
              sysData.lastMeasuredValue === null
                ? 0
                : (sysData.lastMeasuredValue as number),
            heartRate:
              bpHeartRateData.lastMeasuredValue === null
                ? 0
                : (bpHeartRateData.lastMeasuredValue as number),
            dias:
              diasData.lastMeasuredValue === null
                ? 0
                : (diasData.lastMeasuredValue as number)
          },
          lastMeasuredDevice: sysData.lastMeasuredDevice,
          lastMeasuredNote: sysData.lastMeasuredNote,
          lastMeasuredTime: sysData.lastMeasuredTime
        };
        return data;
      }
    } else {
      return getLatestMeasurementsWithCode(
        // must be fixed
        'UserState.currentUser.id',
        observationCode as ObservationCode
      );
    }
    return undefined;
  }

  public static async getEcgGraphWaveValues(
    organizationId: string,
    selectedTimeSpan: TimeSpan,
    _createdAt: string,
    // must be fixed
    userId = 'UserState.selectedUser?.id || UserState.currentUser.id'
  ): Promise<GraphicWaveValues[]> {
    const { getGraphicInformation } = new ObservationService();

    const [ecgGraphWaveInfo] = await Promise.all([
      getGraphicInformation(organizationId, {
        code: ObservationCode.ECG,
        timespan: selectedTimeSpan,
        _createdAt,
        subject: `Patient/${userId}`
      })
    ]);
    return ecgGraphWaveInfo as GraphicWaveValues[];
  }

  public static getQuestionnaireResponse = async (
    userId: string,
    questionnaireType: QuestionnaireTypes
  ): Promise<QuestionnaireResponse> => {
    const { getLatestQuestionnaireResponse } = new QuestionnaireService();

    return getLatestQuestionnaireResponse({
      userId,
      lang: 'en',
      questionnaire: questionnaireType
    });
  };

  // public static getQuestionnaireResponse = async (
  //   userId: string,
  //   questionnaireType: QuestionnaireTypes,
  // ): Promise<IQuestionnaireResponse> => {
  //   const response = await MiddlewareService.getLatestQuestionnaireResponse({
  //     userId,
  //     questionnaire: questionnaireType,
  //     lang: "en_EN",
  //   });
  //   return response;
  // };
}
