import { ResourceTypes } from '@actimi/core-migration';

export const SubjectResources: { [RT in ResourceTypes]: RT } = {
  Patient: 'Patient',
  Observation: 'Observation',
  Practitioner: 'Practitioner',
  LimitValues: 'LimitValues',
  CommunicationRequest: 'CommunicationRequest',
  QuestionnaireResponse: 'QuestionnaireResponse',
  MedicationRequest: 'MedicationRequest',
  Medication: 'Medication',
  Messages: 'Messages',
  AllergyIntolerance: 'AllergyIntolerance',
  AuditEvent: 'AuditEvent',
  PatientRiskScore: 'PatientRiskScore',
  Organization: 'Organization',
  FileSystem: 'FileSystem',
  User: 'User',
  ClinicInvitation: 'ClinicInvitation',
  Condition: 'Condition'
};
