import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import countries from 'i18n-iso-countries';
import deJSON from './translations/de';
import enJSON from './translations/en';
import esJSON from './translations/es';
import frJSON from './translations/fr';
import aeJSON from './translations/ae';
import zhJSON from './translations/zh';
import 'moment/min/moment-with-locales';
import 'moment/locale/de';
import 'moment/locale/en-gb';

const resources = {
  de: { translation: deJSON },
  en: { translation: enJSON },
  es: { translation: esJSON },
  fr: { translation: frJSON },
  ae: { translation: aeJSON },
  cn: { translation: zhJSON }
};

if (localStorage.getItem('i18nextLng') === null) {
  localStorage.setItem('i18nextLng', 'en');
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    keySeparator: false,
    fallbackLng: 'en',
    react: {
      useSuspense: true
    },
    interpolation: {
      escapeValue: false
    }
  });

i18n.on('languageChanged', (currentLanguage) => {
  if (currentLanguage === 'de') {
    moment.locale('de');
    window.location.reload();
  } else if (currentLanguage === 'en') {
    moment.locale('en');
    window.location.reload();
  } else {
    moment.locale('en');
    window.location.reload();
  }
});

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));

export default i18n;
