/* eslint-disable eqeqeq */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { AuditEvent } from '@actimi/core-migration';
import { formatLogs, ILogRow } from '../content/applications/Logs/log-utils';
import getActimiCoreLazy from '../utils/actimi-core';

export interface LogHistoryState {
  isLoading: boolean;
  logs: AuditEvent[];
  formattedLogs: ILogRow[];
  total: number;
  rowHeight: number;
}

const initialState: LogHistoryState = {
  isLoading: true,
  formattedLogs: [],
  logs: [],
  total: 0,
  rowHeight: 80
};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setIsLoading(
      state: LogHistoryState,
      action: PayloadAction<{
        loading: boolean;
      }>
    ) {
      const { loading } = action.payload;
      state.isLoading = loading;
    },
    setLogs(
      state: LogHistoryState,
      action: PayloadAction<{
        logs: AuditEvent[];
        total: number;
      }>
    ) {
      const { logs, total } = action.payload;
      state.formattedLogs = formatLogs(logs).filter((x) => x != undefined);
      state.logs = logs;
      state.isLoading = false;
      state.total = total;
    },
    reset(state: LogHistoryState) {
      state.logs = [];
      state.isLoading = true;
    },
    setRowHeight(
      state: LogHistoryState,
      action: PayloadAction<{
        height: number;
      }>
    ) {
      const { height } = action.payload;
      state.rowHeight = height;
    }
  }
});

export const { reducer } = slice;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const fetchLogs =
  (
    userId: string,
    page: number,
    count: number,
    typeAutocomplete: string,
    sortAutocomplete: string,
    startDate: Date,
    endDate: Date
  ): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setIsLoading({ loading: true }));
    const { LogsService } = await getActimiCoreLazy();
    const { getLogs } = new LogsService();
    const logs = await getLogs({
      userId,
      page,
      count,
      typeAutocomplete,
      sortAutocomplete,
      startDate,
      endDate
    });
    dispatch(
      slice.actions.setLogs({
        logs: logs.logs,
        total: logs.total
      })
    );
  };
export const setRowHeight =
  (height: number): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setRowHeight({ height }));
  };

export default slice;
