/* eslint-disable array-callback-return */
import * as _ from 'lodash';
import moment from 'moment';
import { TFunction } from 'react-i18next';
import {
  GraphicInformation,
  GraphicInformationValue,
  IValue,
  Annotation,
  MONTHLY,
  TimeSpan,
  WEEKLY,
  YEARLY,
  Observation
} from '@actimi/core-migration';
import { isMultipleGraphicDataType } from './Graphic';

export interface IFormattedBpSysDataObject {
  readonly marker: string;
  readonly y: number;
}

export interface IFormattedBpDiasDataObject {
  readonly marker: string;
  readonly y0: number;
}

export type FormattedBloodPressureGraphDataObject = {
  marker: string;
  sysAverage: number;
  y: number;
} & {
  marker: string;
  diasAverage: number;
  y0: number;
};

export type FormattedBpWithHeartRate = {
  marker: string;
  sysAverage: number;
  y: number;
} & {
  marker: string;
  diasAverage: number;
  y0: number;
} & {
  marker: string;
  heartRateAverage: number;
  y0: number;
};

export type _FormattedBpGraphDataCard = {
  sysId: string;
  diasId: string;
  heartRateId: string;
  sysValue: number | number[];
  diasValue: number | number[];
  dateTime?: string;
  heartRateValue: number | number[];
  sysDevice: Observation['device'];
  sysNote: Annotation[] | undefined;
};

export interface IFormattedSortableGraphicData extends GraphicInformationValue {
  sortValue: number;
}
export type ValidatedEcgGraphWaveValueObject = {
  readonly x: number;
  readonly y: number;
};

export function divideData(
  data: GraphicInformationValue[][],
  label: number
): GraphicInformationValue[] {
  return data[label];
}
export function findLastValueIndex(data: GraphicInformationValue[]): number {
  let valueIndex = 0;
  let counter = 0;
  data.forEach((d) => {
    if (d.averageOnTime !== 0) {
      valueIndex = counter;
    }
    counter += 1;
  });
  return valueIndex;
}

export const emptyTodayArrayGenerator = () => {
  const emptyArr = [];
  for (let index = 0; index <= 23; index++) {
    emptyArr.push({
      marker: index > 9 ? `${index}` : `0${index}`,
      values: [],
      max: 0,
      min: 0,
      averageOnTime: 0
    });
  }
  return emptyArr;
};
export const emptyWeeklyArrayGenerator = () => {
  const emptyArr = [];
  for (let index = 0; index <= 6; index++) {
    emptyArr.push({
      marker: moment().subtract(index, 'days').format('dddd'),
      values: [],
      max: 0,
      min: 0,
      averageOnTime: 0
    });
  }

  const reversedArr = emptyArr?.reverse();
  return reversedArr;
};

export const emptyMonthlyArrayGenerator = (selectedDateTime: string) => {
  const emptyArr = [];
  for (let index = 0; index <= 29; index++) {
    emptyArr.push({
      marker: `${moment(selectedDateTime).subtract(index, 'days').format('D')}`,
      values: [],
      max: 0,
      min: 0,
      averageOnTime: 0
    });
  }

  const reversedArr = emptyArr?.reverse();
  return reversedArr;
};

export const emptyYearlyArrayGenerator = () => {
  const emptyArr = [];
  for (let index = 0; index <= 11; index++) {
    emptyArr.push({
      marker: `${moment().subtract(index, 'months').get('M') + 1}`,
      values: [],
      max: 0,
      min: 0,
      averageOnTime: 0
    });
  }

  const reversedArr = emptyArr?.reverse();
  return reversedArr;
};

export function findMinMax(
  graphicData: GraphicInformationValue[] | GraphicInformationValue[][]
): { min: number; max: number } {
  let max = 0;
  let min = 200;
  if (isMultipleGraphicDataType(graphicData)) {
    const sysData = divideData(graphicData as GraphicInformationValue[][], 0);
    const diasData = divideData(graphicData as GraphicInformationValue[][], 1);
    const heartRateData = divideData(
      graphicData as GraphicInformationValue[][],
      2
    );
    sysData.forEach((data: GraphicInformationValue) => {
      if (data.max > max) {
        max = data.max;
      }
    });
    diasData.forEach((data) => {
      if (data.min !== 0 && data.min !== null && data.min < min) {
        min = data.min;
      }
    });
    heartRateData.forEach((data) => {
      if (data.min !== 0 && data.min !== null && data.min < min) {
        min = data.min;
      }
    });
  } else if (graphicData !== undefined) {
    for (const data of graphicData) {
      if (data.max > max) {
        max = data.max;
      }
      if (data.min !== 0 && data.min !== null && data.min < min) {
        min = data.min;
      }
    }
  }
  return {
    max,
    min
  };
}
export function getAverageLineYearly(
  data: GraphicInformationValue[] | undefined
): { marker: string; value: number | null }[] | undefined {
  let sum = 0;
  let index = 0;
  const averages: { marker: string; value: number | null }[] = [];
  if (data) {
    data.forEach((_data: GraphicInformationValue) => {
      if (_data.averageOnTime !== 0) {
        sum += _data.averageOnTime;
        index++;
        averages.push({
          marker: _data.marker.substring(0, 3),
          value: parseFloat((sum / index).toFixed(4))
        });
      } else {
        return null;
      }
    });
    return averages;
  }
}
export function getAverageLine(
  data: GraphicInformationValue[]
): { marker: string; value: number | null }[] {
  let sum = 0;
  let index = 0;
  let pointer = 0;
  const averages: { marker: string; value: number | null }[] = [];
  data.forEach((_data: GraphicInformationValue) => {
    if (pointer < findLastValueIndex(data) + 1) {
      if (_data.averageOnTime !== 0) {
        sum += _data.averageOnTime;
        index++;
        averages.push({
          marker: _data.marker.substring(0, 3),
          value: parseFloat((sum / index).toFixed(4))
        });
      } else {
        averages.push({
          marker: _data.marker.substring(0, 3),
          value:
            sum !== 0 && index !== 0
              ? parseFloat((sum / index).toFixed(4))
              : null
        });
      }
    } else {
      averages.push({
        marker: _data.marker.substring(0, 3),
        value: null
      });
    }
    pointer++;
  });
  return averages;
}
export const combineBloodPressureCardValues = (
  bpGraphData: GraphicInformationValue[][]
): { sysValues: IValue[]; diasValues: IValue[]; heartRateValues: IValue[] } => {
  const bpSysValues: IValue[] = [];
  const bpDiasValues: IValue[] = [];
  const bpHeartRateValues: IValue[] = [];
  bpGraphData[0].forEach((x) => bpSysValues.push(...(x.values as IValue[])));
  bpGraphData[1].forEach((x) => bpDiasValues.push(...(x.values as IValue[])));
  bpGraphData[2].forEach((x) =>
    bpHeartRateValues.push(...(x.values as IValue[]))
  );
  const _bpSysValues = bpSysValues;
  const _bpDiasValues = bpDiasValues;
  const _bpHeartRateValues = bpHeartRateValues;
  _bpSysValues.sort(
    (a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
  );
  _bpDiasValues.sort(
    (a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
  );
  _bpHeartRateValues.sort(
    (a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
  );
  return {
    sysValues: _bpSysValues,
    diasValues: _bpDiasValues,
    heartRateValues: _bpHeartRateValues
  };
};
export const combineDetailedBloodPressureObject = (
  givenData: GraphicInformationValue[][]
): FormattedBloodPressureGraphDataObject[] => {
  return _.values(
    _.merge(
      _.keyBy(
        givenData[0].map((x) => ({
          marker: x.marker,
          y: x.averageOnTime,
          sysAverage: x.averageOnTime
        })),
        'marker'
      ),
      _.keyBy(
        givenData[1].map((x) => ({
          marker: x.marker,
          y0: x.averageOnTime,
          diasAverage: x.averageOnTime
        })),
        'marker'
      )
    )
  );
};
export const combineDetailedBpWithHeartRate = (
  givenData: GraphicInformationValue[][]
): FormattedBpWithHeartRate[] => {
  return _.values(
    _.merge(
      _.keyBy(
        givenData[0].map((x) => ({
          marker: x.marker,
          y: x.averageOnTime,
          sysAverage: x.averageOnTime
        })),
        'marker'
      ),
      _.keyBy(
        givenData[1].map((x) => ({
          marker: x.marker,
          y0: x.averageOnTime,
          diasAverage: x.averageOnTime
        })),
        'marker'
      ),
      _.keyBy(
        givenData[2].map((x) => ({
          marker: x.marker,
          y0: x.averageOnTime,
          heartRateAverage: x.averageOnTime
        })),
        'marker'
      )
    )
  );
};

export const validateEcgGraphWaveValue = (
  values: number[]
): ValidatedEcgGraphWaveValueObject[] => {
  if (values.length === 0) {
    return [];
  }
  const formattedDataSets: ValidatedEcgGraphWaveValueObject[] = [];
  values
    .filter((x) => x < 3000 && x > -3000)
    .map((val, index) => {
      formattedDataSets.push({ x: index, y: val });
    });
  return formattedDataSets;
};

export function combineBloodPressureObject(
  givenData: GraphicInformationValue[][]
): (IFormattedBpSysDataObject & IFormattedBpDiasDataObject)[] {
  const sysData: IFormattedBpSysDataObject[] = [];
  const diasData: IFormattedBpDiasDataObject[] = [];
  const sys = divideData(givenData, 0);
  const dias = divideData(givenData, 1);
  sys.forEach((s) => {
    sysData.push({
      marker: s.marker.substring(0, 3),
      y: s.averageOnTime
    });
  });
  dias.forEach((d) => {
    diasData.push({
      marker: d.marker.substring(0, 3),
      y0: d.averageOnTime
    });
  });
  const merged = _.merge(
    _.keyBy(sysData, 'marker'),
    _.keyBy(diasData, 'marker')
  );
  return _.values(merged);
}

export const sortTimeSpan = (
  time: string,
  selectedTimeSpan: TimeSpan
): number => {
  switch (selectedTimeSpan) {
    case TimeSpan.WEEK:
      switch (time) {
        case WEEKLY.MONDAY:
          return 1;
        case WEEKLY.TUESDAY:
          return 2;
        case WEEKLY.WEDNESDAY:
          return 3;
        case WEEKLY.THURSDAY:
          return 4;
        case WEEKLY.FRIDAY:
          return 5;
        case WEEKLY.SATURDAY:
          return 6;
        case WEEKLY.SUNDAY:
          return 7;
        default:
          return 0;
      }
    case TimeSpan.MONTH:
      switch (time) {
        case MONTHLY.FIRST_WEEK:
          return 1;
        case MONTHLY.SECOND_WEEK:
          return 2;
        case MONTHLY.THIRD_WEEK:
          return 3;
        case MONTHLY.FOURTH_WEEK:
          return 4;
        default:
          return 0;
      }
    case TimeSpan.YEAR:
      switch (time) {
        case YEARLY.JANUARY:
          return 1;
        case YEARLY.FEBRUARY:
          return 2;
        case YEARLY.MARCH:
          return 3;
        case YEARLY.APRIL:
          return 4;
        case YEARLY.MAY:
          return 5;
        case YEARLY.JUNE:
          return 6;
        case YEARLY.JULY:
          return 7;
        case YEARLY.AUGUST:
          return 8;
        case YEARLY.SEPTEMBER:
          return 9;
        case YEARLY.OCTOBER:
          return 10;
        case YEARLY.NOVEMBER:
          return 11;
        case YEARLY.DECEMBER:
          return 12;
        default:
          return 0;
      }
    default:
      return 0;
  }
};

export const sortSingleGraphicData = (
  graphicData: GraphicInformationValue[]
): GraphicInformationValue[] => {
  return graphicData
    .map((t) => {
      return {
        ...t,
        values: t.values.sort(
          (u, w) =>
            new Date(w.dateTime).getTime() - new Date(u.dateTime).getTime()
        )
      };
    })
    .sort(
      (y, x) =>
        Math.max(...x.values.map((q) => new Date(q.dateTime).getTime())) -
        Math.max(...y.values.map((k) => new Date(k.dateTime).getTime()))
    );
};

export const sortMultipleGraphicData = (
  graphicData: GraphicInformationValue[][]
): GraphicInformationValue[][] => {
  return [
    graphicData[0]
      .map((t) => {
        return {
          ...t,
          values: t.values.sort(
            (u, w) =>
              new Date(w.dateTime).getTime() - new Date(u.dateTime).getTime()
          )
        };
      })
      .sort(
        (y, x) =>
          Math.max(...x.values.map((q) => new Date(q.dateTime).getTime())) -
          Math.max(...y.values.map((k) => new Date(k.dateTime).getTime()))
      ),
    graphicData[1]
      .map((t) => {
        return {
          ...t,
          values: t.values.sort(
            (u, w) =>
              new Date(w.dateTime).getTime() - new Date(u.dateTime).getTime()
          )
        };
      })
      .sort(
        (y, x) =>
          Math.max(...x.values.map((q) => new Date(q.dateTime).getTime())) -
          Math.max(...y.values.map((k) => new Date(k.dateTime).getTime()))
      ),
    graphicData[2]
      .map((t) => {
        return {
          ...t,
          values: t.values.sort(
            (u, w) =>
              new Date(w.dateTime).getTime() - new Date(u.dateTime).getTime()
          )
        };
      })
      .sort(
        (y, x) =>
          Math.max(...x.values.map((q) => new Date(q.dateTime).getTime())) -
          Math.max(...y.values.map((k) => new Date(k.dateTime).getTime()))
      )
  ];
};

export const formatGraphicData = (
  graphData: GraphicInformation,
  timeSpan: TimeSpan,
  selectedDateTime: string,
  t: TFunction
): GraphicInformation => {
  switch (timeSpan) {
    case TimeSpan.TODAY:
      const today = _.difference(
        emptyTodayArrayGenerator(),
        graphData?.values.map((val) => val?.marker)
      ).map((x) => graphData?.values.find((y) => y?.marker === x?.marker) || x);
      return {
        ...graphData,
        values: today
      };
    case TimeSpan.WEEK:
      const translatedValuesArr = graphData?.values?.map((x) => {
        return {
          ...x,
          marker: t(`${x?.marker}`)
        };
      });

      const wkly = _.difference(
        emptyWeeklyArrayGenerator(),
        graphData?.values?.map((val) => t(`${val?.marker}`))
      ).map(
        (x) =>
          translatedValuesArr?.find((y) => y?.marker === t(`${x?.marker}`)) || {
            ...x,
            marker: t(`${x?.marker}`)
          }
      );

      return {
        ...graphData,
        values: wkly
      };
    case TimeSpan.MONTH:
      const mnthly = _.difference(
        emptyMonthlyArrayGenerator(selectedDateTime),
        graphData?.values?.map((val) => val?.marker)
      ).map(
        (x) => graphData?.values?.find((y) => y?.marker === x?.marker) || x
      );

      return {
        ...graphData,
        values: mnthly
      };
    case TimeSpan.YEAR:
      const yearly = _.difference(
        emptyYearlyArrayGenerator(),
        graphData?.values.map((val) => val?.marker)
      ).map((x) => graphData?.values.find((y) => y?.marker === x?.marker) || x);
      return {
        ...graphData,
        values: yearly
      };
    default:
      throw new Error('Unknown Time Span');
  }
};

export const formatECGGraphicData = (
  graphData: GraphicInformation,
  timeSpan: TimeSpan,
  selectedDateTime: string
): GraphicInformation => {
  switch (timeSpan) {
    case TimeSpan.TODAY:
      const today = _.difference(
        emptyTodayArrayGenerator(),
        JSON.parse(graphData?.value).map((val) => val?.marker)
      ).map(
        (x) =>
          JSON.parse(graphData?.value).find((y) => y?.marker === x?.marker) || x
      );
      return {
        ...graphData,
        values: today
      };
    case TimeSpan.WEEK:
      const wkly = _.difference(
        emptyWeeklyArrayGenerator(),
        JSON.parse(graphData?.value).map((val) => val?.marker)
      ).map(
        (x) =>
          JSON.parse(graphData?.value).find((y) => y?.marker === x?.marker) || x
      );
      return {
        ...graphData,
        values: wkly
      };
    case TimeSpan.MONTH:
      const mnthly = _.difference(
        emptyMonthlyArrayGenerator(selectedDateTime),
        JSON.parse(graphData?.value)?.map((val) => val?.marker)
      ).map(
        (x) =>
          JSON.parse(graphData?.value)?.find((y) => y?.marker === x?.marker) ||
          x
      );

      return {
        ...graphData,
        values: mnthly
      };
    case TimeSpan.YEAR:
      const yearly = _.difference(
        emptyYearlyArrayGenerator(),
        JSON.parse(graphData?.value).map((val) => val?.marker)
      ).map(
        (x) =>
          JSON.parse(graphData?.value).find((y) => y?.marker === x?.marker) || x
      );
      return {
        ...graphData,
        values: yearly
      };
    default:
      throw new Error('Unknown Time Span');
  }
};

export const validateBpGraphDataObjectArray = (data: {
  sysValues: IValue[];
  diasValues: IValue[];
  heartRateValues: IValue[];
}): boolean => {
  return (
    data.sysValues.length === data.diasValues.length &&
    data.sysValues.length > 0 &&
    data.heartRateValues.length > 0
  );
};

export const validateMultipleGraphData = (
  data: GraphicInformationValue[][]
): boolean => {
  return (
    data[0] &&
    data[0].length > 0 &&
    data[1] &&
    data[1].length > 0 &&
    data[2] &&
    data[2].length > 0
  );
};

export const validateBloodPressureGraphicData = (
  bpSysValues: GraphicInformationValue[],
  bpDiasValues: GraphicInformationValue[],
  bpHeartRateValues: GraphicInformationValue[]
): _FormattedBpGraphDataCard[] | undefined => {
  const isValidMultipleGraphData = validateMultipleGraphData([
    bpSysValues,
    bpDiasValues,
    bpHeartRateValues
  ]);

  if (isValidMultipleGraphData) {
    // TODO: What is being done here unnecessary,
    // all these observations allready should be fetched by using _revinclude=hasMember parameter and grouping accordingly
    // and the rest must be validated on the api layer not in the components.

    const zipped = combineBloodPressureCardValues([
      bpSysValues,
      bpDiasValues,
      bpHeartRateValues
    ]);
    const dateTimes = new Set(
      zipped.sysValues.map((v) => v.dateTime).filter((x) => !!x)
    );
    zipped.diasValues = zipped.diasValues.filter((v) =>
      dateTimes.has(v.dateTime)
    );
    zipped.heartRateValues = zipped.heartRateValues.filter((v) =>
      dateTimes.has(v.dateTime)
    );

    const isValidGraphDataObjectArray = validateBpGraphDataObjectArray(zipped);

    if (isValidGraphDataObjectArray) {
      const { sysValues, diasValues, heartRateValues } = zipped;
      return sysValues
        .map((_x, i) => {
          return {
            sysValue: sysValues[i].value,
            diasValue: diasValues[i].value,
            heartRateValue: heartRateValues[i]
              ? heartRateValues[i].value
              : heartRateValues[0].value,
            diasId: diasValues[i].id,
            dateTime: diasValues[i].dateTime,
            sysId: sysValues[i].id,
            heartRateId: heartRateValues[i]
              ? heartRateValues[i].id
              : heartRateValues[0].id,
            sysDevice: sysValues[i].device,
            sysNote: sysValues[i].note
          };
        })
        .sort(
          (a, b) =>
            new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
        );
    }
  }
};

export const formatSingleGraphicData = (
  graphicData: GraphicInformationValue[]
): IValue[] => {
  const singleGraphicValues: IValue[] = [];
  graphicData?.forEach((x) =>
    x?.values?.forEach((y) => singleGraphicValues.push(y))
  );
  return singleGraphicValues.sort(
    (a, b) =>
      new Date(b?.dateTime)?.getTime() - new Date(a?.dateTime)?.getTime()
  );
};

export const fillWeeklyGraphData = (
  graphicDataValues: GraphicInformationValue[],
  dataToCompare: GraphicInformationValue
): IFormattedSortableGraphicData[] => {
  return graphicDataValues.map((x) => {
    if (x.values.length > 0) {
      return {
        ...x,
        sortValue: new Date(x.values[0].dateTime).getTime()
      };
    }
    const weekDayAbs =
      getWeekDayValue(dataToCompare.marker) - getWeekDayValue(x.marker);
    let _sortValue: number;
    if (weekDayAbs > 0) {
      _sortValue = Number(
        moment(dataToCompare.values[0].dateTime)
          .subtract(weekDayAbs, 'days')
          .format('x')
      );
    } else {
      _sortValue = Number(
        moment(dataToCompare.values[0].dateTime)
          .add(Math.abs(weekDayAbs), 'days')
          .format('x')
      );
    }
    return {
      ...x,
      sortValue: _sortValue
    };
  });
};

export const getWeekDayValue = (weekDay: string): number => {
  switch (weekDay) {
    case WEEKLY.MONDAY:
      return 1;
    case WEEKLY.TUESDAY:
      return 2;
    case WEEKLY.WEDNESDAY:
      return 3;
    case WEEKLY.THURSDAY:
      return 4;
    case WEEKLY.FRIDAY:
      return 5;
    case WEEKLY.SATURDAY:
      return 6;
    case WEEKLY.SUNDAY:
      return 7;
    default:
      break;
  }
};

export const getMinMaxDateForWeeklyData = (
  graphicData: IFormattedSortableGraphicData[] | undefined
): string => {
  if (graphicData) {
    return `${moment(graphicData[0].sortValue).format(
      'DD MMMM YYYY'
    )} - ${moment(graphicData[graphicData.length - 1].sortValue).format(
      'DD MMMM YYYY'
    )}`;
  }
  return `${moment().startOf('week').format('D')} - ${moment()
    .endOf('week')
    .format('DD MMMM YYYY')}`;
};

export const handleTimeSpanDateChange = (
  timeSpan: TimeSpan,
  dateTime: string,
  isNext: boolean
): string => {
  switch (timeSpan) {
    case TimeSpan.MONTH:
      if (isNext) {
        return moment(dateTime).add(30, 'days').endOf('day').toISOString();
      }
      return moment(dateTime).subtract(30, 'days').startOf('day').toISOString();
    case TimeSpan.TODAY:
      if (isNext) {
        return moment(dateTime).add(1, 'day').toISOString();
      }
      return moment(dateTime).subtract(1, 'day').toISOString();
    case TimeSpan.YEAR:
      if (isNext) {
        return moment(dateTime).add(1, 'year').toISOString();
      }
      return moment(dateTime).subtract(1, 'year').toISOString();
    case TimeSpan.WEEK:
      if (isNext) {
        return moment(dateTime).add(1, 'week').toISOString();
      }
      return moment(dateTime).subtract(1, 'week').toISOString();
    default:
      throw new Error('Unknown timeSpan');
  }
};

export const handleTimeSpanDateChangeForPrevBodyWeight = (
  dateTime: string,
  isNext: boolean
): string => {
  if (isNext) {
    return moment(dateTime).add(1, 'week').toISOString();
  }
  return moment(dateTime).subtract(1, 'week').toISOString();
};

export const getGraphicDataCreatedAtParam = (
  minDate: string,
  maxDate: string
): string => {
  return `le${maxDate}&ge${minDate}`;
};
