import { useRef, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Popover,
  Typography,
  styled
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@material-ui/icons/LockOpenTwoTone';
import { useNavigate } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import { useSelector } from 'src/store';
import { Skeleton } from '@mui/material';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

function HeaderUserbox() {
  const { t }: { t: any } = useTranslation();

  const navigate = useNavigate();
  const { logout, user } = useAuth();
  const { authPractitionerInfo } = useSelector((state) => state.clinic);

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
      // const { ChatService } = await getActimiCoreLazy();
      // const { changeUserOnlineStatus } = new ChatService(socket);
      // changeUserOnlineStatus(
      //   authPractitionerInfo?.id,
      //   false,
      //   new Date().getTime().toString()
      // );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        {authPractitionerInfo === undefined ? (
          <Skeleton width={40} height={70} animation="wave" />
        ) : (
          <Avatar
            variant="rounded"
            alt={authPractitionerInfo?.name.given}
            src={authPractitionerInfo?.avatar}
          />
        )}
        <Box
          component="span"
          sx={{
            display: { xs: 'none', md: 'inline-block' }
          }}
        >
          <UserBoxText>
            {authPractitionerInfo === undefined ? (
              <Skeleton width={120} animation="wave" />
            ) : (
              <UserBoxLabel variant="body1">
                {`${authPractitionerInfo?.name?.given} ${authPractitionerInfo?.name?.family}`
                  .replace('null', '')
                  .replace('undefined', '')}
              </UserBoxLabel>
            )}
            {authPractitionerInfo === undefined ? (
              <Skeleton width={80} animation="wave" />
            ) : (
              <UserBoxDescription variant="body2">
                {t(
                  `${
                    typeof user?.roleName === 'string'
                      ? `${user.roleName
                          ?.charAt(0)
                          ?.toUpperCase()}${user.roleName?.slice(1)}`
                      : 'Doctor'
                  }`
                )}
              </UserBoxDescription>
            )}
          </UserBoxText>
        </Box>
        <Box
          component="span"
          sx={{
            display: { xs: 'none', sm: 'inline-block' }
          }}
        >
          <ExpandMoreTwoToneIcon
            sx={{
              ml: 1
            }}
          />
        </Box>
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar
            variant="rounded"
            alt={authPractitionerInfo?.name.given}
            src={authPractitionerInfo?.avatar}
          />
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {`${authPractitionerInfo?.name?.given} ${authPractitionerInfo?.name?.family}`
                .replace('null', '')
                .replace('undefined', '')}
            </UserBoxLabel>
            <Typography
              fontWeight={100}
              variant="subtitle1"
              color="rgb(153, 158, 187)"
            >
              {t(
                `${
                  user?.roleName
                    ? `${user.roleName
                        ?.charAt(0)
                        ?.toUpperCase()}${user.roleName?.slice(1)}`
                    : 'Doctor'
                }`
              )}
            </Typography>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />
        {/* <List
          sx={{
            p: 1
          }}
          component="nav"
        >
          <ListItem
            onClick={() => {
              handleClose();
            }}
            button
          >
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Profile')} />
          </ListItem>
          <ListItem
            onClick={() => {
              handleClose();
            }}
            button
          >
            <InboxTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Inbox')} />
          </ListItem>
          <ListItem
            onClick={() => {
              handleClose();
            }}
            button
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Projects')} />
          </ListItem>
        </List> */}
        <Divider />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1
              }}
            />
            {t('Sign out')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
