import {
  DashboardFactory,
  ICommunicationRequest,
  IPatient,
  PatientFactory,
  PatientRiskScore
} from '@actimi/core-migration';
import { ITableItem } from 'src/models/dashboard-table';
import { socket } from './socket';

socket.on('message', (data) => {
  console.log('s-message', data);
  if (data === 'Unauthorized') {
    console.error(new Error('Authorization with Socket failed!'));
  }
});

socket.on('connect_error', () => {
  console.log('socket connection error');
  setTimeout(() => {
    if (!socket.connected) {
      socket.connect();
    }
  }, 5000);
});

const startListeningCommunicationRequest = (
  organizationId: string,
  onCommunicationRequestEvent: (
    communicationRequest: ICommunicationRequest
  ) => void
) => {
  const isListeningCommunicationRequest = socket.hasListeners(
    `communication-request-${organizationId}`
  );
  if (!isListeningCommunicationRequest) {
    socket.on(`communication-request-${organizationId}`, (ev) => {
      onCommunicationRequestEvent(ev);
    });
  }
};

const startListening = (
  patientRiskScores: ITableItem[],
  onPatientRiskScoreEvent: (obs: ITableItem) => void,
  onPatientEvent: (patient: IPatient) => void
) => {
  if (!socket.connected) {
    socket.connect();
  }

  if (!socket.hasListeners(`patient`)) {
    socket.on('patient', async (patient) =>
      onPatientEvent(new PatientFactory().format(patient))
    );
    socket.on('patient-risk-score', (patientRiskScore: PatientRiskScore) =>
      onPatientRiskScoreEvent(new DashboardFactory(patientRiskScore).tableData)
    );
  }
  const body = patientRiskScores.reduce((acc, patientRiskScore) => {
    acc.push({ resourceType: 'PatientRiskScore', id: patientRiskScore.id });
    acc.push(patientRiskScore.patient);
    return acc;
  }, [] as any[]);
  socket.emit('subscribe-resources', body);
};

const removeListeners = (patientRiskScores: ITableItem[]) => {
  const body = patientRiskScores.reduce((acc, patientRiskScore) => {
    acc.push({ resourceType: 'PatientRiskScore', id: patientRiskScore.id });
    acc.push(patientRiskScore.patient);
    return acc;
  }, [] as any[]);
  socket.emit('unsubscribe-resources', body);
};

const cleanUp = () => {
  socket.removeAllListeners();
};

export const UpdateSubscriptionSocket = {
  startListening,
  removeListeners,
  startListeningCommunicationRequest,
  cleanUp
};
