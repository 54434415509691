import {
  GraphicInformation,
  getMeasurementTypeKey,
  GraphicInformationValue,
  ObservationCode,
  BloodPressureDataId,
  BloodPressureDataValue,
  MeasureDataSourceId
} from '@actimi/core-migration';

export const isGraphDataNull = (
  data: GraphicInformation[] | undefined
): boolean => {
  // Check if data exists and all of its latestMeasured values are not nullish
  // If not return true
  console.assert(
    (!data ||
      data[0].latestMeasuredValue === null ||
      data[1].latestMeasuredValue === null ||
      data[2].latestMeasuredValue === null) ===
      (!data?.length || data.some((d) => d.latestMeasuredValue === null)),
    `isGraphDataNull wrong data: ${JSON.stringify(data)}`
  );
  if (!data?.length) return true;
  return data.some((d) => d.latestMeasuredValue === null);
};

export const getNullGraphicInformationData = (
  observationCode: ObservationCode
): GraphicInformation => {
  return {
    latestMeasuredValue: null,
    latestMeasuredDevice: {
      id: MeasureDataSourceId.ACTIMI_MANUAL.toString(),
      resourceType: 'Device'
    },
    latestMeasuredNote: undefined,
    latestMeasuredDateTime: '',
    name: getMeasurementTypeKey(observationCode),
    patientId: '',
    average: null,
    values: []
  };
};

export const isMultipleGraphDataResponse = (
  response: unknown
): response is GraphicInformation[] => {
  const partial: Partial<GraphicInformation[]> = response as Partial<
    GraphicInformation[]
  >;
  return partial !== undefined && partial[0] !== undefined;
};

export const isMultipleGraphicDataType = (
  graphicData: unknown
): graphicData is GraphicInformationValue[][] => {
  const partial: Partial<GraphicInformationValue[][]> = graphicData as Partial<
    GraphicInformationValue[][]
  >;
  return (
    partial !== undefined &&
    partial[0] !== undefined &&
    partial[0][0] !== undefined
  );
};

export const isMultipleFormattedGraphicData = (
  graphicData: unknown
): graphicData is GraphicInformationValue[][] => {
  const partial: Partial<GraphicInformationValue[][]> = graphicData as Partial<
    GraphicInformationValue[][]
  >;
  return partial[0] !== undefined && partial[0][0] !== undefined;
};

export const isBloodPressureSingleData = (
  response: unknown
): response is BloodPressureDataValue => {
  const partial: Partial<BloodPressureDataValue> =
    response as Partial<BloodPressureDataValue>;
  return partial.sys !== undefined;
};

export const isBloodPressureSingleDataId = (
  response: unknown
): response is BloodPressureDataId => {
  const partial: Partial<BloodPressureDataId> =
    response as Partial<BloodPressureDataId>;
  return partial !== undefined && partial.sysId !== undefined;
};
