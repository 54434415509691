/* eslint-disable eqeqeq */
import { ILimitValues } from '@actimi/core-migration';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import getActimiCoreLazy from '../utils/actimi-core';

export type IntervalValues = {
  max: number;
  min: number;
  riskScore: number;
};

export interface IPeriodLimitValue {
  /**
   * The period of the limit value (in days e.g. 1, 3, 7).
   */
  readonly timespan: number;
  readonly limit: ILimitValueSet;
}

export interface ILimitValueSet {
  readonly low: IntervalValues;
  readonly medium: IntervalValues;
  readonly high: IntervalValues;
}

export interface ECGLimitValueSet {
  readonly riskScore: number;
  readonly interpretation: string;
}

// --------------------------------- TODO: CHANGE THE BODY WEIGHT INTERFACE LIKE BELOW ---------------------------

// export interface IPeriodLimitValue {
//   /**
//    * The period of the limit value (in days e.g. 1, 3, 7).
//    */
//   readonly timespan: number;
//   readonly limit: ILimitValueSet;
// }

export interface LimitValueState {
  isLimitValuesLoading: boolean;
  limitValues: ILimitValues[];
}

const initialState: LimitValueState = {
  isLimitValuesLoading: true,
  limitValues: []
};

const slice = createSlice({
  name: 'limit-values',
  initialState,
  reducers: {
    getLimitValue(
      state: LimitValueState,
      action: PayloadAction<{
        limitValue: ILimitValues;
      }>
    ) {
      const { limitValue } = action.payload;
      if (limitValue != undefined) {
        const limitValueIndex = state.limitValues.findIndex(
          (x) => x?.id === limitValue?.id
        );
        if (limitValueIndex > -1) {
          state.limitValues.splice(limitValueIndex, 1, limitValue);
        } else {
          state.limitValues.push(limitValue);
        }
      }
    },
    setLimitValue(
      state: LimitValueState,
      action: PayloadAction<{
        limitValues: ILimitValues[];
      }>
    ) {
      const { limitValues } = action.payload;
      state.limitValues?.push(...limitValues);
      state.isLimitValuesLoading = false;
    },
    setLimitValues(
      state: LimitValueState,
      action: PayloadAction<{
        limitValues: ILimitValues[];
      }>
    ) {
      const { limitValues } = action.payload;
      state.limitValues = limitValues;
      state.isLimitValuesLoading = false;
    },
    updateLimitValue(
      state: LimitValueState,
      action: PayloadAction<{
        limitValue: ILimitValues;
      }>
    ) {
      state.isLimitValuesLoading = false;
      const { limitValue } = action.payload;
      const limitValueIndex = state.limitValues.findIndex(
        (x) => x?.id === limitValue?.id
      );
      if (limitValueIndex > -1) {
        state.limitValues.splice(limitValueIndex, 1, limitValue);
      }
    },
    reset(state: LimitValueState) {
      Object.assign(state, initialState);
    }
  }
});

export const { reducer } = slice;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const setLimitValue =
  (limitValues: ILimitValues[], whenDone?: () => void): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setLimitValue({ limitValues }));
    const action = whenDone;
    if (action !== undefined) {
      action();
    }
  };

export const getLimitValue =
  (
    organization: string,
    patientId: string,
    whenDone: (limitValues: ILimitValues) => void
  ): AppThunk =>
  async (dispatch) => {
    const { LimitService } = await getActimiCoreLazy();
    const { getLimitValue } = new LimitService();
    const limitValue = await getLimitValue(organization, patientId);

    dispatch(slice.actions.getLimitValue({ limitValue }));
    whenDone(limitValue);
  };

export const updateLimitValue =
  (
    limitValue: Partial<ILimitValues>,
    bodyWeightLimitValues: {
      oneDay: number[];
      threeDays: number[];
      oneWeek: number[];
    },
    ecg: ECGLimitValueSet[],
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch) => {
    const combined: Partial<ILimitValues> = {
      ...limitValue,
      bodyWeight: [
        {
          timespan: 1,
          limit: {
            low: {
              min: bodyWeightLimitValues?.oneDay?.[0] || 0.9,
              max: bodyWeightLimitValues?.oneDay?.[1] || 1.4,
              riskScore: 2
            },
            medium: {
              min: 0,
              max: 0,
              riskScore: 3
            },
            high: {
              min: 0,
              max: 0,
              riskScore: 4
            }
          }
        },
        {
          timespan: 3,
          limit: {
            low: {
              min: bodyWeightLimitValues?.threeDays?.[0] || 1,
              max: bodyWeightLimitValues?.threeDays?.[1] || 2.5,
              riskScore: 2
            },
            medium: {
              min: 0,
              max: 0,
              riskScore: 3
            },
            high: {
              min: 0,
              max: 0,
              riskScore: 4
            }
          }
        },
        {
          timespan: 7,
          limit: {
            low: {
              min: bodyWeightLimitValues?.oneWeek?.[0] || 1.4,
              max: bodyWeightLimitValues?.oneWeek?.[1] || 1.8,
              riskScore: 2
            },
            medium: {
              min: 0,
              max: 0,
              riskScore: 3
            },
            high: {
              min: 0,
              max: 0,
              riskScore: 4
            }
          }
        }
      ],
      ecg
    };
    const { LimitService } = await getActimiCoreLazy();
    const { createLimitValue } = new LimitService();
    const resp = await createLimitValue(combined as ILimitValues);
    dispatch(
      slice.actions.getLimitValue({
        limitValue: resp
      })
    );
    const action = whenDone;
    if (action !== undefined) {
      action();
    }
  };
