import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { ITableItem } from 'src/models/dashboard-table';
import { DashboardTableFilter } from 'src/utils/DashbordTable';
import { IObservation, ObservationCode } from '@actimi/core-migration';
import getActimiCoreLazy from '../utils/actimi-core';

export const sortFieldMap = {
  symptoms: ObservationCode.SYMPTOM,
  bloodPressure: ObservationCode.SYSTOLIC_BLOOD_PRESSURE,
  heartRate: ObservationCode.HEART_RATE,
  bodyWeight: ObservationCode.BODY_WEIGHT,
  ecg: ObservationCode.ECG,
  lastMeasured: 'lastMeasurementDate'
};

interface ISortParams {
  field: keyof typeof sortFieldMap;
  order: 'asc' | 'desc';
}
export interface IFilterParams {
  searchArg: string;
  taskFilter: DashboardTableFilter;
}

export interface MainDashboardState {
  isLoading: boolean;
  total: number;
  filter: IFilterParams;
  sort: ISortParams | null;
  tableData: ITableItem[];
}

const initialState: MainDashboardState = {
  isLoading: true,
  tableData: [],
  total: 0,
  filter: {
    searchArg: '',
    taskFilter: DashboardTableFilter.ALL_PATIENTS
  },
  sort: null
};

const slice = createSlice({
  name: 'main-dashboard',
  initialState,
  reducers: {
    setFilter(
      state: MainDashboardState,
      action: PayloadAction<{
        filter: IFilterParams;
      }>
    ) {
      const { filter } = action.payload;
      state.filter = filter;
    },
    setSort(
      state: MainDashboardState,
      actions: PayloadAction<ISortParams | null>
    ) {
      state.sort = actions.payload;
    },
    setLoading(
      state: MainDashboardState,
      action: PayloadAction<{
        loading: boolean;
      }>
    ) {
      const { loading } = action.payload;
      state.isLoading = loading;
    },
    updateTableDataLastMeasured(
      state: MainDashboardState,
      action: PayloadAction<{
        observation: IObservation;
      }>
    ) {
      const { observation } = action.payload;

      const itemIndex = state.tableData.findIndex(
        (x) => x.patient.id === observation.subject.split('/')[1]
      );
      let latestMeasurement = state.tableData[itemIndex].lastMeasurements;
      if (itemIndex > -1) {
        switch (observation.code.coding[0].code) {
          case ObservationCode.BODY_WEIGHT:
            latestMeasurement = {
              ...latestMeasurement,
              bodyWeight: {
                ...latestMeasurement.bodyWeight,
                bodyWeight: observation.valueQuantity.value as number,
                previousBodyWeight: latestMeasurement.bodyWeight
                  .bodyWeight as number
              }
            };
            break;
          case ObservationCode.ECG_HEART_RATE:
            latestMeasurement = {
              ...latestMeasurement,
              heartRate: {
                ...latestMeasurement.heartRate,
                heartRate: observation.valueQuantity.value as number,
                previousHeartRate: latestMeasurement.heartRate
                  .heartRate as number
              }
            };
            break;
          case ObservationCode.SYSTOLIC_BLOOD_PRESSURE:
            latestMeasurement = {
              ...latestMeasurement,
              bloodPressure: {
                ...latestMeasurement.bloodPressure,
                systolic: observation.valueQuantity.value as number,
                previousSystolic: latestMeasurement.bloodPressure
                  .systolic as number
              }
            };
            break;
          case ObservationCode.DIASTOLIC_BLOOD_PRESSURE:
            latestMeasurement = {
              ...latestMeasurement,
              bloodPressure: {
                ...latestMeasurement.bloodPressure,
                diastolic: observation.valueQuantity.value as number,
                previousDiastolic: latestMeasurement.bloodPressure
                  .diastolic as number
              }
            };
            break;
          case ObservationCode.BLOOD_PRESSURE_HEART_RATE:
            latestMeasurement = {
              ...latestMeasurement,
              heartRate: {
                ...latestMeasurement.heartRate,
                heartRate: observation.valueQuantity.value as number,
                previousHeartRate: latestMeasurement.heartRate
                  .heartRate as number
              }
            };
            break;
          case ObservationCode.OXYGEN_SATURATION:
            latestMeasurement = {
              ...latestMeasurement,
              spo2: {
                ...latestMeasurement.spo2,
                spo2: observation.valueQuantity.value as number,
                previousSpo2: latestMeasurement.spo2.spo2 as number
              }
            };
            break;
        }
        state.tableData.splice(itemIndex, 1, {
          ...state.tableData[itemIndex],
          lastMeasurements: latestMeasurement
        });
      }
    },
    // updateTableDataSymptoms(
    //   state: MainDashboardState,
    //   action: PayloadAction<{
    //     count: number;
    //     subject: string;
    //   }>
    // ) {
    //   const { count, subject } = action.payload;

    //   const itemIndex = state.tableData.findIndex((x) => x.patient.id === subject);
    //   let latestMeasurement = state.tableData[itemIndex].lastMeasurements;
    //   if (itemIndex > -1) {
    //     latestMeasurement = {
    //       ...latestMeasurement,
    //       symptoms: {
    //         symptom: count,
    //         previousSymptom: latestMeasurement.symptoms.symptom,
    //       }
    //     };
    //     state.tableData.splice(itemIndex, 1, {
    //       ...state.tableData[itemIndex],
    //       lastMeasurements: latestMeasurement
    //     });
    //   }
    // },
    updateTableDataItem(
      state: MainDashboardState,
      action: PayloadAction<{
        tableItem: ITableItem;
      }>
    ) {
      const { tableItem } = action.payload;
      let found = false;
      for (let i = 0; i < state.tableData.length; i++) {
        if (state.tableData[i].patient.id === tableItem.patient.id) {
          state.tableData[i] = tableItem;
          found = true;
          break;
        }
      }
      if (!found) {
        state.tableData.push(tableItem);
      }
    },
    getTableData(
      state: MainDashboardState,
      action: PayloadAction<{
        tableData: ITableItem[];
        total: number;
      }>
    ) {
      const { tableData, total } = action.payload;
      state.tableData = tableData;
      state.total = total;
      state.isLoading = false;
    },
    reset(state: MainDashboardState) {
      Object.assign(state, initialState);
    }
  }
});

export const { reducer } = slice;

export const { setSort } = slice.actions;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const setFilter =
  (filter: IFilterParams): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.setFilter({
        filter
      })
    );
  };

export const setLoading =
  (loading: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.setLoading({
        loading
      })
    );
  };

export const getTableData =
  (
    meId: string,
    organizationId: string,
    sort,
    searchArg,
    taskFilter,
    whenDone: (patientRiskScores: ITableItem[]) => void | undefined,
    count = 30,
    page = 1,
    isPba = false
  ): AppThunk =>
  async (dispatch) => {
    const { PatientService } = await getActimiCoreLazy();
    const { getTableData: getTableDataAPI } = new PatientService();
    const resp = await getTableDataAPI({
      meId,
      organizationId,
      count,
      page,
      sort,
      searchArg,
      taskFilter,
      isPba
    });
    dispatch(
      slice.actions.getTableData({
        tableData: resp.items,
        total: resp.total
      })
    );
    if (whenDone) {
      whenDone(resp.items);
    }
  };

export const updateTableDataLastMeasured =
  (observation: IObservation): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.updateTableDataLastMeasured({
        observation
      })
    );
  };

export const updateTableItem =
  (tableItem: ITableItem): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateTableDataItem({ tableItem }));
  };

// export const updateTableDataSymptoms =
//   (count: number, subject: string): AppThunk =>
//   async (dispatch) => {
//     dispatch(
//       slice.actions.updateTableDataSymptoms({
//         count,
//         subject
//       })
//     );
//   };

export default slice;
