import { Medication, MedicationRequest } from '@actimi/core-migration';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import getActimiCoreLazy from '../utils/actimi-core';
import i18n from '../i18n/i18n';

export interface MedicalInfoState {
  selectedMedication: Medication | null;
  // createMedication: IMedication;
  allMedications: MedicationRequest[];
  medicationList: Medication[];
}

const initialState: MedicalInfoState = {
  selectedMedication: null,
  allMedications: [],
  medicationList: []
};

const slice = createSlice({
  name: 'medical-info',
  initialState,
  reducers: {
    reset(state: MedicalInfoState) {
      Object.assign(state, initialState);
    },
    getMedicationReq(
      state: MedicalInfoState,
      action: PayloadAction<{
        medicationReq: MedicationRequest[];
      }>
    ) {
      const { medicationReq } = action.payload;
      state.allMedications = medicationReq;
    },
    getMedicationsList(
      state: MedicalInfoState,
      action: PayloadAction<{
        medicationsList: Medication[];
      }>
    ) {
      const { medicationsList } = action.payload;

      state.medicationList = medicationsList;
    }
  }
});

export const { reducer } = slice;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const getAllMedications =
  (
    patientId: string,
    patientOrganizationId: string,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch) => {
    const { MedicationService } = await getActimiCoreLazy();
    const { getMedicationRequest } = new MedicationService();

    const medicationReq = await getMedicationRequest(
      patientOrganizationId,
      undefined,
      patientId
    );

    dispatch(slice.actions.getMedicationReq({ medicationReq }));
    whenDone?.();
  };

export const getMedicationsListByName =
  (name: string, whenDone?: () => void): AppThunk =>
  async (dispatch) => {
    const { MedicationService } = await getActimiCoreLazy();
    const { getMedication } = new MedicationService();
    if (name) {
      const medicationsList = await getMedication({
        name,
        country: i18n.language,
        language: i18n.language
      });
      dispatch(slice.actions.getMedicationsList({ medicationsList }));
    }
    whenDone?.();
  };

export const getMedicationsListById =
  (id: string, whenDone?: () => void): AppThunk =>
  async (dispatch) => {
    const { MedicationService } = await getActimiCoreLazy();
    const { findMedication } = new MedicationService();

    const medicationsList = await findMedication(id);
    dispatch(
      slice.actions.getMedicationsList({ medicationsList: [medicationsList] })
    );
    whenDone?.();
  };

export const patchMedication =
  (
    id: string,
    body: Partial<Omit<MedicationRequest, 'subject'>>,
    patientOrganizationId: string,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch) => {
    const { MedicationService } = await getActimiCoreLazy();
    const { patchMedicationRequest } = new MedicationService();
    await patchMedicationRequest(id, body, patientOrganizationId);
    whenDone?.();
  };

export const resetCreateMedication = (): AppThunk => async (dispatch) => {
  dispatch(resetCreateMedication());
};
