/* eslint-disable eqeqeq */
import moment from 'moment';
import { DEFAULT_LIMIT_VALUES } from 'src/defaults/limit-values';
import { ITableItem } from 'src/models/dashboard-table';
import { ECGLimitValueSet, ILimitValueSet } from 'src/slices/limit-values';
import { DefaultRiskScoreValues } from './RiskScores';

export enum DashboardTableFilter {
  ALL_PATIENTS = 'All Patients',
  FOR_NURSE = 'For Nurse',
  FOR_DOCTOR = 'For Doctor'
}

export interface IRisk {
  text: string;
  riskScore: number;
  color?: string;
}

/* const RiskColorsDebug = {
  NORMAL: '#c1e4d3',
  LOW: '#fffce5',
  MEDIUM: '#fde9cc',
  HIGH: '#fdccd5'
} */

export const RiskColors = {
  NORMAL: '#FFFFFF',
  LOW: '#FFF5AA',
  MEDIUM: '#FFC979',
  HIGH: '#FF9DAF',
  GREEN: '#c1e4d3'
};

export const getRiskChangeArrow = ({
  prev,
  current
}: {
  prev: IRisk;
  current: IRisk;
}) => {
  const prevScore = prev?.riskScore;
  const currentScore = current?.riskScore;
  if (prevScore === currentScore) return 'none';
  return prevScore > currentScore ? 'down' : 'up';
};

export const getRiskScoreSymptoms = (
  val: number,
  limitValues: ILimitValueSet = DEFAULT_LIMIT_VALUES.symptoms
): IRisk => {
  const { low, medium, high } = limitValues;

  if (val <= low.max) {
    return {
      text: DefaultRiskScoreValues.normal,
      riskScore: low?.riskScore - 1,
      color: '#FFFFFF'
    };
  }
  if (val > low.max && val <= medium.max) {
    return {
      text: DefaultRiskScoreValues.lowSeverity,
      riskScore: low?.riskScore,
      color: RiskColors.LOW
    };
  }
  if (val > medium.max && val <= high.max) {
    return {
      text: DefaultRiskScoreValues.mediumSeverity,
      riskScore: medium?.riskScore,
      color: RiskColors.MEDIUM
    };
  }
  if (val > high.max) {
    return {
      text: DefaultRiskScoreValues.highSeverity,
      riskScore: high?.riskScore,
      color: RiskColors.HIGH
    };
  }
  return {
    text: DefaultRiskScoreValues.undefinedRange,
    riskScore: 0,
    color: '#FFFFFF'
  };
};

export const getRiskScoreBloodOxygen = (
  val: number,
  limitValues: ILimitValueSet
): IRisk => {
  throw Error('Blood oxygen not being used!');
};

export const getRiskScoreBodyWeight = (riskScore: number): IRisk => {
  if (riskScore === undefined) {
    return {
      text: DefaultRiskScoreValues.undefinedRange,
      riskScore,
      color: '#DEDEDE'
    };
  }

  if (riskScore === 0) {
    return {
      text: DefaultRiskScoreValues.normal,
      riskScore,
      color: RiskColors.NORMAL
    };
  }
  if (riskScore === 2) {
    return {
      text: DefaultRiskScoreValues.lowSeverity,
      riskScore,
      color: RiskColors.LOW
    };
  }
  if (riskScore === 4) {
    return {
      text: DefaultRiskScoreValues.highSeverity,
      riskScore,
      color: RiskColors.HIGH
    };
  }
};

export const getRiskScoreLastMeasuredTime = (date: string) => {
  const a = moment();
  const b = moment(date);

  if (a.diff(b, 'hours') <= 24) {
    return {
      text: DefaultRiskScoreValues.normal,
      displayText:
        b.format('DD') === a.format('DD') ? 'Today' : 'less than 24h',
      riskScore: 0,
      color: RiskColors.NORMAL
    };
  }
  if (a.diff(b, 'hours') > 24 && a.diff(b, 'hours') <= 36) {
    return {
      text: DefaultRiskScoreValues.mediumSeverity,
      displayText: 'more than 24h',
      riskScore: 1,
      color: RiskColors.MEDIUM
    };
  }
  if (a.diff(b, 'hours') > 36) {
    return {
      text: DefaultRiskScoreValues.highSeverity,
      displayText: 'more than 36h',
      riskScore: 2,
      color: RiskColors.HIGH
    };
  }

  return {
    text: DefaultRiskScoreValues.undefinedRange,
    riskScore: 0,
    color: '#FFFFFF'
  };
};

// Intervals of each low, medium, high tested respectively
export const getRiskScoreMinMaxInterval = (
  val: number,
  limitValues: ILimitValueSet
): IRisk => {
  const { low, medium, high } = limitValues;
  if (isNaN(val) || val == undefined) {
    return {
      text: DefaultRiskScoreValues.normal,
      riskScore: 0,
      color: RiskColors.NORMAL
    };
  }
  if (val >= low.min && val <= low.max) {
    return {
      text: DefaultRiskScoreValues.normal,
      riskScore: 0,
      color: RiskColors.NORMAL
    };
  }
  if (
    (val >= medium.min && val <= low.min - 1) ||
    (val >= low.max + 1 && val <= medium.max)
  ) {
    return {
      text: DefaultRiskScoreValues.normal,
      color: RiskColors.NORMAL,
      riskScore: low?.riskScore
    };
  }
  if (
    (val >= high.min && val <= medium.max - 1) ||
    (val >= medium.max + 1 && val <= high.max)
  ) {
    return {
      text: DefaultRiskScoreValues.normal,
      color: RiskColors.NORMAL,
      riskScore: medium?.riskScore
    };
  }
  if (val <= high.min - 1 || val >= high.max + 1) {
    return {
      text: DefaultRiskScoreValues.normal,
      color: RiskColors.NORMAL,
      riskScore: high?.riskScore
    };
  }

  return {
    text: DefaultRiskScoreValues.normal,
    riskScore: 0,
    color: RiskColors.NORMAL
  };
  // const { low, medium, high } = limitValues;

  // if (low.min <= val && val <= low.max) {
  //   return {
  //     text: DefaultRiskScoreValues.normal,
  //     riskScore: low?.riskScore - 2,
  //     color: RiskColors.NORMAL
  //   };
  // }
  // if (medium.min <= val && val <= medium.max) {
  //   return {
  //     text: DefaultRiskScoreValues.lowSeverity,
  //     riskScore: low?.riskScore,
  //     color: RiskColors.LOW
  //   };
  // }
  // if (high.min <= val && val <= high.max) {
  //   return {
  //     text: DefaultRiskScoreValues.mediumSeverity,
  //     riskScore: medium?.riskScore,
  //     color: RiskColors.MEDIUM
  //   };
  // }
  // if (high.max < val) {
  //   return {
  //     text: DefaultRiskScoreValues.highSeverity,
  //     riskScore: high?.riskScore,
  //     color: RiskColors.HIGH
  //   };
  // }

  // return {
  //   text: DefaultRiskScoreValues.undefinedRange,
  //   riskScore: 0,
  //   color: '#FFFFFF'
  // };
};

const getSystolicRiskScore = (
  val: number,
  limitValuesSystolic: ILimitValueSet = DEFAULT_LIMIT_VALUES.bloodPressure
): IRisk => {
  const valueSet = limitValuesSystolic;

  const lowMin = valueSet?.low?.min;
  const lowMax = valueSet?.low?.max;
  const medMin = valueSet?.medium?.min;
  const medMax = valueSet?.medium?.max;
  const highMin = valueSet?.high?.min;
  const highMax = valueSet?.high?.max;

  if (val >= lowMin && val <= lowMax) {
    return {
      text: DefaultRiskScoreValues.normal,
      color: RiskColors.NORMAL,
      riskScore: 0
    };
  }
  if (
    (val >= medMin && val <= lowMin - 1) ||
    (val >= lowMax + 1 && val <= medMax)
  ) {
    return {
      text: DefaultRiskScoreValues.lowSeverity,
      color: RiskColors.LOW,
      riskScore: 1
    };
  }
  if (
    (val >= highMin && val <= medMax - 1) ||
    (val >= medMax + 1 && val <= highMax)
  ) {
    return {
      text: DefaultRiskScoreValues.mediumSeverity,
      color: RiskColors.MEDIUM,
      riskScore: 2
    };
  }
  if (val <= highMin - 1 || val >= highMax + 1) {
    return {
      text: DefaultRiskScoreValues.highSeverity,
      color: RiskColors.HIGH,
      riskScore: 3
    };
  }
  return {
    text: DefaultRiskScoreValues.undefinedRange,
    color: RiskColors.NORMAL,
    riskScore: 0
  };
};

const getDiastolicRiskScore = (
  val: number,
  limitValuesDiastolic: ILimitValueSet = DEFAULT_LIMIT_VALUES.bloodPressureDiastolic
): IRisk => {
  const valueSet = limitValuesDiastolic;

  const lowMin = valueSet?.low?.min;
  const lowMax = valueSet?.low?.max;
  const medMin = valueSet?.medium?.min;
  const medMax = valueSet?.medium?.max;
  const highMin = valueSet?.high?.min;
  const highMax = valueSet?.high?.max;

  if (val >= lowMin && val <= lowMax) {
    return {
      text: DefaultRiskScoreValues.normal,
      color: RiskColors.NORMAL,
      riskScore: 0
    };
  }
  if (
    (val >= medMin && val <= lowMin - 1) ||
    (val >= lowMax + 1 && val <= medMax)
  ) {
    return {
      text: DefaultRiskScoreValues.lowSeverity,
      color: RiskColors.LOW,
      riskScore: 1
    };
  }
  if (
    (val >= highMin && val <= medMax - 1) ||
    (val >= medMax + 1 && val <= highMax)
  ) {
    return {
      text: DefaultRiskScoreValues.mediumSeverity,
      color: RiskColors.MEDIUM,
      riskScore: 2
    };
  }
  if (val <= highMin - 1 || val >= highMax + 1) {
    return {
      text: DefaultRiskScoreValues.highSeverity,
      color: RiskColors.HIGH,
      riskScore: 3
    };
  }
  return {
    text: DefaultRiskScoreValues.undefinedRange,
    color: '#DEDEDE',
    riskScore: undefined
  };
};

export const getRiskScoreBP = (
  sysVal: number,
  diaVal: number,
  limitValuesSystolic: ILimitValueSet,
  limitValuesDiastolic: ILimitValueSet
) => {
  const sysSeverity = getSystolicRiskScore(sysVal, limitValuesSystolic);
  const diaSeverity = getDiastolicRiskScore(diaVal, limitValuesDiastolic);

  if (
    sysSeverity.riskScore !== undefined &&
    sysSeverity.riskScore >= diaSeverity.riskScore
  ) {
    return {
      text: sysSeverity.text,
      color: sysSeverity.color,
      riskScore: sysSeverity.riskScore
    };
  }
  if (
    diaSeverity.riskScore !== undefined &&
    diaSeverity.riskScore >= sysSeverity.riskScore
  ) {
    return {
      text: diaSeverity.text,
      color: diaSeverity.color,
      riskScore: diaSeverity.riskScore
    };
  }
  return {
    text: DefaultRiskScoreValues.undefinedRange,
    color: '#FFFFFF',
    riskScore: undefined
  };
};

export const getRiskScoreHR = (
  val: number,
  limitValues: ILimitValueSet = DEFAULT_LIMIT_VALUES.bloodPressure
): IRisk => {
  const lowMin = limitValues?.low?.min;
  const lowMax = limitValues?.low?.max;
  const medMin = limitValues?.medium?.min;
  const medMax = limitValues?.medium?.max;
  const highMin = limitValues?.high?.min;
  const highMax = limitValues?.high?.max;

  if (val >= lowMin && val <= lowMax) {
    return {
      text: DefaultRiskScoreValues.normal,
      color: RiskColors.NORMAL,
      riskScore: 0
    };
  }
  if (
    (val >= medMin && val <= lowMin - 1) ||
    (val >= lowMax + 1 && val <= medMax)
  ) {
    return {
      text: DefaultRiskScoreValues.lowSeverity,
      color: RiskColors.LOW,
      riskScore: 1
    };
  }
  if (
    (val >= highMin && val <= medMax - 1) ||
    (val >= medMax + 1 && val <= highMax)
  ) {
    return {
      text: DefaultRiskScoreValues.mediumSeverity,
      color: RiskColors.MEDIUM,
      riskScore: 2
    };
  }
  if (val <= highMin - 1 || val >= highMax + 1) {
    return {
      text: DefaultRiskScoreValues.highSeverity,
      color: RiskColors.HIGH,
      riskScore: 2
    };
  }
  return {
    text: DefaultRiskScoreValues.undefinedRange,
    color: RiskColors.NORMAL,
    riskScore: 0
  };
};

// HeartRate
export const getRiskScoreHeartRate = (
  val: number,
  limitValues: ILimitValueSet = DEFAULT_LIMIT_VALUES.heartRate
): IRisk => getRiskScoreMinMaxInterval(val, limitValues);

export const ecgStatusColor = (
  status: string,
  ecgLimitValues?: ECGLimitValueSet[]
) => {
  const findRiskScore = ecgLimitValues?.find(
    (x) => x?.interpretation === status
  );

  if (findRiskScore) {
    switch (findRiskScore?.riskScore) {
      case 2:
        return RiskColors.MEDIUM;
      case 0:
        return RiskColors.NORMAL;
      default:
        return RiskColors.NORMAL;
    }
  }
};

declare type ILastMeasurements = ITableItem['lastMeasurements'];
declare type ILastMeasurementKey =
  | 'symptoms'
  | 'bloodPressure'
  | 'heartRate'
  | 'bodyWeight'
  | 'ecg';
const lastMeasurementKeys: ILastMeasurementKey[] = [
  'symptoms',
  'bloodPressure',
  'heartRate',
  'bodyWeight',
  'ecg'
];

export function getLastMeasured(lastMeasurements: ILastMeasurements) {
  const lastMeasuredKey = lastMeasurementKeys.reduce((latestMkey?, mkey?) => {
    if (!latestMkey) return mkey;
    const latestMDate = new Date(
      lastMeasurements[latestMkey].dateTime ?? 0
    ).getTime();
    const mDate = new Date(lastMeasurements[mkey].dateTime ?? 0).getTime();
    return latestMDate > mDate ? latestMkey : mkey;
  }, null);

  return {
    type: getMeasurementTypeName(lastMeasuredKey),
    dateTime: lastMeasurements[lastMeasuredKey].dateTime
  };
}

const getMeasurementTypeName = (key: ILastMeasurementKey) => {
  switch (key) {
    case 'bloodPressure':
      return 'BP';
    case 'heartRate':
      return 'HR';
    case 'symptoms':
      return 'SYMPTOM';
    case 'bodyWeight':
      return 'WEIGHT';
    case 'ecg':
      return 'ECG';
    default:
      return 'OTHER';
  }
};
