import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICareTeam, IObservation, IUserProfile } from '@actimi/core-migration';
import { Notifications } from 'src/layouts/SidebarLayout/Header/Buttons/Alarms';
import { NotificationFactory } from 'src/utils/NotificationFactory';
import _ from 'lodash';
import { AppDispatch, AppThunk } from '../store';
import getActimiCoreLazy from '../utils/actimi-core';

export interface IUserNotifications {
  readonly id: string;
  readonly to: string;
  readonly createdAt: string;
  readonly read: boolean;
  readonly message: string;
  readonly from: string;
  readonly importance: 'low' | 'medium' | 'high' | 'maximum';
}

export interface UserNotificationState {
  userNotifications: IUserNotifications[];
  notificationBadge: number;
  careTeamPaginationData: ICareTeam[];
  observationPaginationData: Notifications[];
  observations: IObservation[];
}

const initialState: UserNotificationState = {
  userNotifications: [],
  notificationBadge: 0,
  careTeamPaginationData: [],
  observationPaginationData: [],
  observations: []
};
const slice = createSlice({
  name: 'user-notification',
  initialState,
  reducers: {
    getNotifications(
      state: UserNotificationState,
      action: PayloadAction<{ notifications: IUserNotifications[] }>
    ) {
      const { notifications } = action.payload;
      notifications.forEach((notification) => {
        const index = state.userNotifications.findIndex(
          (notificationItem) => notificationItem.id === notification.id
        );
        if (index > -1) {
          state.userNotifications.splice(index, 1, notification);
        } else {
          state.userNotifications.push(notification);
        }
      });
    },

    getMoreNotifications(
      state: UserNotificationState,
      action: PayloadAction<{ notifications: IUserNotifications[] }>
    ) {
      const { notifications } = action.payload;
      notifications.forEach((notification) => {
        const index = state.userNotifications.findIndex(
          (notificationItem) => notificationItem.id === notification.id
        );
        if (index > -1) {
          state.userNotifications.splice(index, 1, notification);
        } else {
          state.userNotifications.push(notification);
        }
      });
    },

    getUnseenNotifications(
      state: UserNotificationState,
      action: PayloadAction<{ notificationBadge: number }>
    ) {
      const { notificationBadge } = action.payload;
      state.notificationBadge = notificationBadge;
    },
    markAsRead(
      state: UserNotificationState,
      action: PayloadAction<{ body: Array<{ id: string; read: boolean }> }>
    ) {
      for (const { id, read } of action.payload.body) {
        const index = state.userNotifications.findIndex((x) => x.id === id);
        state.userNotifications.splice(index, 1, {
          ...state.userNotifications[index],
          read
        });
      }
      state.notificationBadge = state.userNotifications.filter(
        (x) => !x.read
      ).length;
    },
    getCareTeamPaginationData(
      state: UserNotificationState,
      action: PayloadAction<{ careTeamPaginationData: ICareTeam[] }>
    ) {
      const { careTeamPaginationData } = action.payload;

      state.careTeamPaginationData = careTeamPaginationData;
    },
    getObservationPaginationData(
      state: UserNotificationState,
      action: PayloadAction<{
        observationPaginationData: IObservation[];
        userList: IUserProfile[];
      }>
    ) {
      const { observationPaginationData, userList } = action.payload;

      const data = NotificationFactory.formatObservations(
        observationPaginationData,
        userList
      );
      state.observations.push(...observationPaginationData);
      state.observationPaginationData = _.sortBy(
        data,
        (x) => x.creationDate
      ).reverse();
    },
    getMoreObservationData(
      state: UserNotificationState,
      action: PayloadAction<{
        observationPaginationData: IObservation[];
        userList: IUserProfile[];
      }>
    ) {
      const { observationPaginationData, userList } = action.payload;
      const observations = state.observations.concat(
        ...observationPaginationData
      );
      const data = NotificationFactory.formatObservations(
        observations,
        userList
      );
      state.observations.push(...observations);
      state.observationPaginationData = _.sortBy(
        data,
        (x) => x.creationDate
      ).reverse();
    },
    reset(state: UserNotificationState) {
      Object.assign(state, initialState);
    }
  }
});

export const { reducer } = slice;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const getNotifications =
  (meId: string, _page: number, _count: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { UserNotificationsService } = await getActimiCoreLazy();
    const { getNotifications } = new UserNotificationsService();
    const resp = await getNotifications({ meId, _count, _page });
    dispatch(slice.actions.getNotifications({ notifications: resp }));
  };

export const getMoreNotifications =
  (
    meId: string,
    _count: number,
    _page: number,
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { UserNotificationsService } = await getActimiCoreLazy();
    const { getNotifications } = new UserNotificationsService();
    const resp = await getNotifications({
      meId,
      _count,
      _page
    });
    if (resp.length > 0) {
      dispatch(slice.actions.getMoreNotifications({ notifications: resp }));
      if (whenDone) {
        whenDone();
      }
    }
  };

export const getMoreObservationData =
  (
    userId: string,
    page: number,
    count: number,
    userList: IUserProfile[],
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { UserNotificationsService } = await getActimiCoreLazy();
    const { getAllNotifications } = new UserNotificationsService();
    const resp = await getAllNotifications({
      meId: userId,
      page,
      count,
      members: userList.map((x) => ({
        ...x,
        name: { given: x.name, family: '' }
      }))
    });

    dispatch(
      slice.actions.getMoreObservationData({
        observationPaginationData: resp,
        userList
      })
    );
    if (whenDone) {
      whenDone();
    }
  };

export const getUnseenNotifications =
  (meId: string, whenDone: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { UserNotificationsService } = await getActimiCoreLazy();
    const { getUnseenNotifications: getUnseenNotificationsAPI } =
      new UserNotificationsService();
    const resp = await getUnseenNotificationsAPI(meId);
    dispatch(slice.actions.getUnseenNotifications({ notificationBadge: resp }));
    const action = whenDone;
    if (action !== undefined) {
      action();
    }
  };

export const markAsRead =
  (body: Array<{ id: string; read: boolean }>): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { UserNotificationsService } = await getActimiCoreLazy();
    const { markNotificationsAsRead } = new UserNotificationsService();
    dispatch(slice.actions.markAsRead({ body }));
    await markNotificationsAsRead(body);
  };

export const getCareTeamPaginationData =
  (
    userId: string,
    page?: number,
    count?: number,
    whenDone?: (resp: ICareTeam[]) => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { CareTeamService } = await getActimiCoreLazy();
    const resp = await new CareTeamService().getCareTeams({
      participant: userId,
      page,
      count
    });
    dispatch(
      slice.actions.getCareTeamPaginationData({ careTeamPaginationData: resp })
    );
    const action = whenDone;
    if (action !== undefined) {
      action(resp);
    }
  };

export const getObservationPaginationData =
  (
    userId: string,
    page: number,
    count: number,
    userList: IUserProfile[],
    whenDone?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { UserNotificationsService } = await getActimiCoreLazy();
    const { getAllNotifications } = new UserNotificationsService();
    const resp = await getAllNotifications({
      meId: userId,
      page,
      count,
      members: userList.map((x) => ({
        ...x,
        name: { given: x.name, family: '' }
      }))
    });
    dispatch(
      slice.actions.getObservationPaginationData({
        observationPaginationData: resp,
        userList
      })
    );
    if (whenDone) {
      whenDone();
    }
  };

export default slice;
