import {
  useState,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import useAuth from 'src/hooks/useAuth';
import { chatSocket } from 'src/utils/socket';

type IChatConnStatus = {
  connecting: boolean;
  connectionError: boolean;
  connectionOverriden: boolean;
  isConnected: boolean;
  reconnect: Function;
  socketId?: string;
};

const defaultData: IChatConnStatus = {
  connecting: false,
  connectionError: false,
  connectionOverriden: false,
  isConnected: chatSocket.connected,
  socketId: chatSocket.id,
  reconnect: () => {}
};

const ChatConnStatus = createContext(defaultData);

type Props = {
  children: ReactNode;
};

export function ChatConnStatusProvider({ children }: Props) {
  const [connecting, setConnecting] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [connectionOverriden, setConnectionOverriden] = useState(false);
  const [isConnected, setIsConnected] = useState(chatSocket.connected);

  const { userId } = useAuth();

  useEffect(() => {
    chatSocket.on('connect', () => {
      setConnecting(false);
      setConnectionError(false);
      setConnectionOverriden(false); // Reset
      setIsConnected(true);
    });
    chatSocket.on('disconnect', (reason) => {
      console.log('chat disconnected. Reason:', reason);
      setConnecting(false);
      setIsConnected(false);
    });

    chatSocket.on('connect_error', (err: Error) => {
      console.log('chat connection err', err.message);
      setConnectionError(true);
      setConnecting(false);
    });
    chatSocket.on('connection-overriden', () => {
      setConnectionOverriden(true);
    });
  }, []);

  const connect = useCallback(() => {
    if (!chatSocket.connected) {
      setConnecting(true);
      setConnectionError(false);
      setConnectionOverriden(false);
      chatSocket.connect();
    }
  }, []);

  useEffect(() => {
    if (userId) {
      connect();
    } else {
      setConnecting(false);
      setConnectionError(false);
      setConnectionOverriden(false);
      chatSocket.disconnect();
    }
  }, [userId, connect]);

  const value = useMemo(
    () => ({
      connecting,
      connectionError,
      connectionOverriden,
      isConnected,
      reconnect: connect,
      socketId: chatSocket.id
    }),
    [connectionOverriden, isConnected, connect, connecting, connectionError]
  );

  return (
    <ChatConnStatus.Provider value={value}>{children}</ChatConnStatus.Provider>
  );
}

export const useChatConnStatus = () => useContext(ChatConnStatus);
