import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ICareTeam, ICareTeamParticipant } from '@actimi/core-migration';
import type { AppThunk } from 'src/store';
import axios from 'src/utils/axios';
import getActimiCoreLazy from '../utils/actimi-core';

export interface IActiveUsers {
  readonly name: string;
  readonly avatar: string;
  readonly careTeamName: string;
  readonly measurements: number;
  readonly rank: number;
}

export interface CareTeamState {
  selectedCareTeam: ICareTeam | undefined;
  activeUsersRank: IActiveUsers[];
  participants: ICareTeamParticipant[];
  careTeamList: ICareTeam[];
}

const initialState: CareTeamState = {
  selectedCareTeam: undefined,
  activeUsersRank: [],
  participants: [],
  careTeamList: []
};
export interface ICareTeamAvatar {
  id: number;
  url: string;
}

export interface GetCareTeamsQuery {
  participant?: string;
  page?: number;
  count?: number;
}

const slice = createSlice({
  name: 'careteam',
  initialState,
  reducers: {
    getActiveUserByRank(
      state: CareTeamState,
      action: PayloadAction<{
        activeUsersRank: IActiveUsers[];
      }>
    ) {
      const { activeUsersRank } = action.payload;

      state.activeUsersRank = activeUsersRank;
    },
    getCareTeamParticipants(
      state: CareTeamState,
      action: PayloadAction<{
        participants: ICareTeamParticipant[];
      }>
    ) {
      const { participants } = action.payload;
      state.participants = participants;
    },
    setSelectedCareTeam(
      state: CareTeamState,
      action: PayloadAction<{
        careTeam: ICareTeam;
      }>
    ) {
      const { careTeam } = action.payload;
      const careTeamIndex = state.careTeamList.findIndex(
        (x) => x.id === careTeam.id
      );
      if (careTeamIndex > -1) {
        state.selectedCareTeam = state.careTeamList[careTeamIndex];
      } else {
        state.careTeamList.push(careTeam);
        state.selectedCareTeam = careTeam;
      }
    },
    getCareTeam(
      state: CareTeamState,
      action: PayloadAction<{
        careTeam: ICareTeam;
      }>
    ) {
      const { careTeam } = action.payload;
      const careTeamIndex = state.careTeamList.findIndex(
        (x) => x.id === careTeam.id
      );
      if (careTeamIndex > -1) {
        state.careTeamList.splice(careTeamIndex, 1, careTeam);
      } else {
        this.state.careTeamList.push(careTeam);
      }
    },
    getAllCareTeams(
      state: CareTeamState,
      action: PayloadAction<{
        careTeamList: ICareTeam[];
      }>
    ) {
      const { careTeamList } = action.payload;
      state.selectedCareTeam = careTeamList[0];
      state.careTeamList = careTeamList;
    },
    reset(state: CareTeamState) {
      Object.assign(state, initialState);
    }
  }
});

export const { reducer } = slice;

export const reset = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.reset());

export const setSelectedCareTeam =
  (careTeam: ICareTeam): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.setSelectedCareTeam({
        careTeam
      })
    );
  };

export const getActiveUserByRank =
  (careTeamId?: string): AppThunk =>
  async (dispatch) => {
    if (careTeamId === undefined) {
      throw new Error('Selected CareTeam is undefined');
    }
    const resp = await axios.post('/active-users', {
      careTeamId
    });
    dispatch(
      slice.actions.getActiveUserByRank({
        activeUsersRank: resp.data
      })
    );
  };

export const getCareTeamParticipants =
  (
    careTeamId: string,
    callback?: (participants: ICareTeamParticipant[]) => void
  ): AppThunk =>
  async (dispatch) => {
    const { CareTeamService } = await getActimiCoreLazy();
    const resp = await new CareTeamService().getCareTeamParticipants(
      careTeamId
    );
    dispatch(
      slice.actions.getCareTeamParticipants({
        participants: resp
      })
    );
    if (callback !== undefined) {
      callback(resp);
    }
  };

export const getCareTeam =
  (id: string): AppThunk =>
  async (dispatch) => {
    const { CareTeamService } = await getActimiCoreLazy();
    const resp = await new CareTeamService().getCareTeam({ id });
    dispatch(
      slice.actions.getCareTeam({
        careTeam: resp
      })
    );
  };

export const getAllCareTeams =
  (
    participant: string,
    count: number,
    page: number,
    whenDone?: (_participants: ICareTeamParticipant[]) => void
  ): AppThunk =>
  async (dispatch) => {
    const { CareTeamService } = await getActimiCoreLazy();
    const resp = await new CareTeamService().getCareTeams({
      participant,
      page,
      count
    });
    dispatch(
      slice.actions.getAllCareTeams({
        careTeamList: resp
      })
    );
    if (whenDone !== undefined) {
      const _participants = resp
        .map((x) => x.participants)
        .reduce((a, b) => a.concat(b), []);
      whenDone(_participants);
    }
  };

export const patchCareTeam =
  (careTeamId: string, implicitRules: string): AppThunk =>
  async () => {
    const { CareTeamService } = await getActimiCoreLazy();
    await new CareTeamService().patchCareTeam(careTeamId, {
      implicitRules
    });
  };

export default slice;
