import {
  FC,
  ReactNode,
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo
} from 'react';
import axios from 'src/utils/axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { PackRule, packRules, unpackRules } from '@casl/ability/extra';
import {
  isDoctorLoginResponse,
  IUser,
  Organization,
  DoctorAuthService,
  ERoleName
} from '@actimi/core-migration';
import {
  AbilityContext,
  createRulesWithRoleAbility
} from '../services/casl/AbilityContext';

const { register: registerClinicActimiCore, login: doctorLoginAPI } =
  new DoctorAuthService();
interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  userId: string | null;
  user: IUser | null;
  rules: PackRule<any>[] | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (
    username: string,
    password: string,
    errorMessage: string
  ) => Promise<void>;
  logout: () => void;
  register: (
    email: string,
    password: string,
    name: string,
    surname: string,
    phoneNumber: string,
    practiceName: string,
    practicePhoneNumber: string,
    practiceStreetAddress: string,
    practiceCity: string,
    practiceZipCode: string,
    members?: string[],
    avatar?: string
  ) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: IUser | null;
    userId: string;
    rules: PackRule<any>[] | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    userId: string;
    user: IUser;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: IUser;
    userId: string;
  };
};

type SetTempSectionAction = {
  type: 'SET_TEMP_SESSION';
  payload: {
    user: IUser;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | SetTempSectionAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  userId: null,
  rules: null
};

const setSession = (
  userId: string,
  user: IUser,
  rules: PackRule<any>[],
  access_token: string
): void => {
  if (user) {
    localStorage.setItem('userId', userId);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('rules', JSON.stringify(rules));
    localStorage.setItem('accessToken', access_token);
    axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('rules');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, userId, user, rules } = action.payload;

    return {
      ...state,
      userId,
      isAuthenticated,
      isInitialized: true,
      user,
      rules
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user, userId } = action.payload;

    return {
      ...state,
      userId,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null,
    userId: null
  }),
  REGISTER: (state: AuthState, action: RegisterAction): AuthState => {
    const { user, userId } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      userId
    };
  },
  SET_TEMP_SESSION: (
    state: AuthState,
    action: SetTempSectionAction
  ): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const userId = window.localStorage.getItem('userId');
        const user = window.localStorage.getItem('user');
        const rules = window.localStorage.getItem('rules');
        const accessToken = window.localStorage.getItem('accessToken');
        if (user && rules) {
          const parsedUser = JSON.parse(user);
          const parsedRules = JSON.parse(rules);
          AbilityContext.ability.update(unpackRules(parsedRules));
          setSession(userId, parsedUser, parsedRules, accessToken);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              userId,
              isAuthenticated: true,
              user: {
                ...parsedUser,
                name: parsedUser.name
              },
              rules: parsedRules
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              userId,
              isAuthenticated: false,
              user: null,
              rules: null
            }
          });
        }
      } catch (err) {
        // console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            userId: null,
            isAuthenticated: false,
            user: null,
            rules: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = useCallback(
    async (
      username: string,
      password: string,
      errorMessage: string
    ): Promise<void> => {
      const response = await doctorLoginAPI(username, password);
      if (!isDoctorLoginResponse(response)) {
        toast.error(errorMessage);
      } else {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { userId, practitioner, role, access_token } = response;

        const rules = createRulesWithRoleAbility(role);
        AbilityContext.ability.update(rules);
        const packedRules = packRules(rules);

        if (practitioner) {
          setSession(
            userId,
            {
              resourceType: 'Practitioner',
              id: practitioner.id,
              name: practitioner.name,
              email: practitioner.email,
              avatar: practitioner.avatar,
              clinic: practitioner.clinic as Organization,
              roleName: role.context.role as ERoleName
            },
            packedRules,
            access_token
          );
          dispatch({
            type: 'LOGIN',
            payload: {
              userId,
              user: {
                resourceType: 'Practitioner',
                id: practitioner.id,
                name: practitioner.name,
                email: practitioner.email,
                avatar: practitioner.avatar,
                clinic: practitioner.clinic as Organization,
                roleName: role?.context?.role as ERoleName
              }
            }
          });
        }
      }
    },
    []
  );

  const logout = useCallback(async (): Promise<void> => {
    axios.delete('/Session');
    setSession(null, null, null, null);
    window.localStorage.removeItem('user');
    dispatch({ type: 'LOGOUT' });
  }, []);

  const register = useCallback(
    async (
      email: string,
      password: string,
      name: string,
      surname: string,
      phoneNumber: string,
      practiceName: string,
      practicePhoneNumber: string,
      practiceStreetAddress: string,
      practiceCity: string,
      practiceZipCode: string,
      members?: string[],
      avatar?: string
    ): Promise<void> => {
      await registerClinicActimiCore({
        name,
        surname,
        password,
        email,
        phoneNumber,
        practiceName,
        practicePhoneNumber,
        practiceStreetAddress,
        practiceCity,
        practiceZipCode,
        members
      });
      await login(email, password, undefined);
    },
    [login]
  );

  const value = useMemo(
    () => ({
      ...state,
      method: 'JWT' as 'JWT',
      login,
      logout,
      register
    }),
    [state, login, logout, register]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;

export interface IGoogleFit {
  readonly dailySteps: boolean;
  readonly workouts: boolean;
  readonly sleep: boolean;
  readonly calories: boolean;
  readonly weight: boolean;
  readonly bloodPressure: boolean;
  readonly heartRate: boolean;
  readonly bodyTemperature: boolean;
  readonly googleFitAuthorized: boolean;
}

export interface PatientName {
  readonly given: string;
  readonly family?: string;
}
