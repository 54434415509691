export type RiskResult = {
  readonly text: string;
  readonly color: string;
  readonly riskScore: number;
};

export const DefaultRiskScoreValues = {
  normal: 'Normal',
  lowSeverity: 'Low Severity',
  mediumSeverity: 'Medium Severity',
  highSeverity: 'High Severity',
  undefinedRange: 'Undefined Range',
  notComparable: 'Not comparable'
};
