import { useRef, useState } from 'react';

import {
  // IconButton,
  Box,
  List,
  ListItem,
  Typography,
  ListItemText,
  Popover,
  // Tooltip,
  styled
} from '@material-ui/core';
import internationalization from 'src/i18n/i18n';
import { useTranslation } from 'react-i18next';

import deFlag from 'src/assets/icons/germany.svg';
import usFlag from 'src/assets/icons/united.svg';

const SectionHeading = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`
);
const ImageWrapper = styled('img')(
  () => `
        width: 30px;
        height: 30px;
        margin: 3px;
`
);

// const IconButtonWrapper = styled(IconButton)(
//   ({ theme }) => `
//         width: ${theme.spacing(6)};
//         height: ${theme.spacing(6)};
// `
// );

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const { t }: { t: any } = useTranslation();
  const getLanguage = i18n.language;

  const switchLanguage = ({ lng }: { lng: any }) => {
    internationalization.changeLanguage(lng);
  };
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  // const handleOpen = (): void => {
  //   setOpen(true);
  // };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      {/* <Tooltip arrow title={t('Language Switcher')}>
        <IconButtonWrapper color="success" ref={ref} onClick={handleOpen}>
          {getLanguage === 'de' && <ImageWrapper alt="German" src={deFlag} />}
          {getLanguage === 'en' && <ImageWrapper alt="English" src={usFlag} />}
          {getLanguage === 'en-US' && (
            <ImageWrapper alt="English" src={usFlag} />
          )}
          {getLanguage === 'en-GB' && (
            <ImageWrapper alt="English" src={usFlag} />
          )}
        </IconButtonWrapper>
      </Tooltip> */}
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{
            minWidth: 280,
            border: '1px solid #dedede',
            borderRadius: 1
          }}
        >
          <SectionHeading variant="body2" color="text.primary">
            {t('Language Switcher')}
          </SectionHeading>
          <List
            sx={{
              p: 2
            }}
            component="nav"
          >
            <ListItem
              className={
                getLanguage === 'en' || getLanguage === 'en-US' ? 'active' : ''
              }
              button
              onClick={() => {
                switchLanguage({ lng: 'en' });
                handleClose();
              }}
              style={{ marginBottom: 5 }}
            >
              <ImageWrapper alt="English" src={usFlag} />
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="English"
              />
            </ListItem>
            <ListItem
              className={getLanguage === 'de' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'de' });
                handleClose();
              }}
            >
              <ImageWrapper alt="German" src={deFlag} />
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="German"
              />
            </ListItem>
          </List>
        </Box>
      </Popover>
    </>
  );
}

export default LanguageSwitcher;
